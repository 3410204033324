<template>
  <div>
    <span class="d-flex justify-content-end">
      <small
        >Les champs avec le signe <RequiredStarComponent /> sont requis.</small
      >
    </span>
    <b-card class="mb-1">
      <!-- <b-link class="brand-logo">
          <AppLogo />
        </b-link> -->
      <!-- <div class="d-flex justify-content-between flex-wrap">
        <h2>Inscription des établissements des industries touristiques</h2>

        <div class="text-right flex-grow-1 mb-3">
          <b-button
            variant="outline-secondary"
            href="/"
          >
            <i class="feather icon-arrow-left" />
            Retourner sur la page d'accueil
          </b-button>
        </div>
      </div> -->

      <div class="row">
        <div class="col-lg-6 col-md-8">
          <h2>Inscription des établissements des industries touristiques</h2>
        </div>

        <div class="col-lg-6 col-md-4 mb-3 text-right">
          <b-button variant="outline-secondary" href="/">
            <i class="feather icon-arrow-left" />
            Retourner sur la page d'accueil
          </b-button>
        </div>
      </div>

      <b-row v-if="!isRegisteredSuccessfully">
        <b-col md="12" lg="12">
          <!-- type_ets -->
          <b-form-group>
            <label for="">Type d'établissement <RequiredStarComponent /></label>
            <v-select
              v-model="form.type_ets"
              type="text"
              :options="typesEts"
              label="nom"
              required="true"
              class="select-size-lg"
              :class="$v.form.type_ets.$error ? 'invalid-input' : ''"
              @close="onTypeEtsInputDirty"
            />
            <div v-if="$v.form.type_ets.$error">
              <small
                v-if="$v.form.type_ets.required.$invalid"
                class="text-danger"
                >Ce champ est requis</small
              >
            </div>
          </b-form-group>
        </b-col>
        <b-col v-if="selectedTypeEtsHasSubTypes" md="12">
          <b-form-group>
            <label for=""
              >Sous type d'établissement <RequiredStarComponent
            /></label>
            <b-form-radio-group
              v-model="form.sous_type_ets.radio"
              @change="onTypeEtsInputDirty"
            >
              <b-form-radio
                v-for="(item, index) of selectedTypeEtsSubTypes"
                :key="'sous_type_ets' + index"
                name="sous_type_ets"
                :value="item"
              >
                {{ item.text }}
                <div
                  v-if="
                    item.requireManualInput &&
                    item.code &&
                    form.sous_type_ets.radio &&
                    form.sous_type_ets.radio.code === item.code
                  "
                >
                  <b-form-input
                    v-model="form.sous_type_ets.input"
                    class="d-inline"
                    type="text"
                    placeholder="Veuillez préciser"
                  />
                  <div v-if="$v.form.sous_type_ets.input.$error">
                    <small
                      v-if="$v.form.sous_type_ets.input.required.$invalid"
                      class="text-danger"
                      >Ce champ est requis</small
                    >
                  </div>
                </div>
              </b-form-radio>
            </b-form-radio-group>
            <div v-if="$v.form.sous_type_ets.radio.$error">
              <small
                v-if="$v.form.sous_type_ets.radio.required.$invalid"
                class="text-danger"
                >Ce champ est requis</small
              >
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mb-0 min-h-78-vh">
      <!-- <b-link class="brand-logo">
          <AppLogo />
        </b-link> -->
      <div
        v-if="isRegisteredSuccessfully"
        class="text-center animate__fadeInRight"
      >
        <i
          class="ri-checkbox-circle-line d-block mx-auto font-10rem text-primary"
        />
        <div>
          <p class="font-bold">
            Votre inscription a été enregistrée avec succès. <br />
          </p>
          <p class="font-bold">
            <!-- Votre compte est validé automatiquement. <br> -->
            Dans le but de vérifier votre adresse email d'établissement, un mail
            vous a été envoyé <br />
            Veuillez vous connectez à votre boîte mail pour valider l'email et
            vous connecter avec votre mot de passe.
          </p>
          <!-- <p class="font-bold">
            Veuillez le consulter pour avoir plus de détails sur les prochaine
            étapes
          </p> -->

          <p class="">
            <b-button variant="link" :to="{ name: 'home-public' }">
              Retour à la page d'accueil
            </b-button>

            <b-button variant="link" :to="{ name: 'auth-login' }">
              Se connecter
            </b-button>
          </p>
        </div>
      </div>

      <!-- form -->
      <b-form
        v-if="!isRegisteredSuccessfully"
        class="auth-register-form mt-2"
        :class="{ animate__fadeInRightBig: isRegisteredSuccessfully }"
        @submit.prevent="submitForm"
      >
        <b-card-header class="d-flex justify-content-between">
          <h3>Informations concernant l'établissement</h3>
        </b-card-header>
        <b-row>
          <!-- ifu -->
          <b-col md="12" lg="4">
            <b-form-group
              label="IFU de l'établissement(Facultatif)"
              label-for="ifu"
            >
              <b-form-input
                id="ifu"
                v-model="form.ifu"
                v-digits-only="{ length: 13 }"
                name="ifu"
                :state="$v.form.ifu.$error ? false : null"
                @blur="$v.form.ifu.$touch"
              />
              <div v-if="$v.form.ifu.$error">
                <small v-if="$v.form.ifu.$invalid" class="text-danger"
                  >Ce champs doit avoir 13 caractères</small
                >
              </div>
            </b-form-group>
          </b-col>
          <!-- rccm -->
          <b-col md="12" lg="4">
            <b-form-group label="RCCM (Facultatif)" label-for="rccm">
              <b-form-input id="rccm" v-model="form.rccm" name="rccm" />
            </b-form-group>
            <!-- <b-form-group label-for="rccm">
              <template v-slot:label>
                <span> RCCM (Facultatif) (exemple : RB/COT/23 A 94737) </span>
              </template>
              <b-input-group
                :class="
                  have_check_rccm && !rccm_value_valid && 'border border-danger'
                "
              >
                <b-form-input
                  id="rccm"
                  v-model="form.rccm"
                  name="rccm"
                  @change="change_rccm()"
                />
                <b-input-group-append is-text>
                  <b-spinner v-if="isLoading_check_rccm" small />
                  <i
                    v-else
                    class="las"
                    :class="
                      rccm_value_valid
                        ? 'la-check iconGreen'
                        : 'la-times iconRed'
                    "
                  />
                </b-input-group-append>
              </b-input-group>
             
              <span>
                <div v-if="form.rccm && !rccm_string_valid">
                  <small class="text-danger"
                    >Ce champ doit respecter le bon format</small
                  >
                </div>
                <div v-if="form.rccm && have_check_rccm && !rccm_value_valid">
                  <small class="text-danger"
                    >Le numéro RCCM n'est pas valide. Veuillez renseigner un
                    numéro valide.</small
                  >
                </div>
                
              </span>
            </b-form-group> -->
          </b-col>
          <!-- agrement -->
          <b-col md="12" lg="4">
            <b-form-group
              label="Numéro Agrément / Licence / Autorisation (Facultatif)"
              label-for="numero_agrement"
            >
              <b-form-input
                id="numero_agrement"
                v-model="form.mumeroAgrement"
                name="numero_agrement"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12" lg="6">
            <!-- nom ets -->
            <b-form-group>
              <label for=""
                >Nom de l'établissement <RequiredStarComponent
              /></label>
              <b-form-input
                id="nomEts"
                v-model="form.nom_ets"
                :state="$v.form.nom_ets.$error ? false : null"
                name="nomEts"
                @blur="$v.form.nom_ets.$touch"
              />
              <div v-if="$v.form.nom_ets.$error">
                <small
                  v-if="$v.form.nom_ets.required.$invalid"
                  class="text-danger"
                  >Ce champ est requis</small
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12" lg="6">
            <!-- adresse_Ets -->
            <b-form-group>
              <label for=""
                >Adresse de l'établissement <RequiredStarComponent
              /></label>
              <b-form-input
                id="adresse_Ets"
                v-model="form.adresse_ets"
                :state="$v.form.adresse_ets.$error ? false : null"
                name="adresse_Ets"
                @blur="$v.form.adresse_ets.$touch"
              />
              <div v-if="$v.form.adresse_ets.$error">
                <small
                  v-if="$v.form.adresse_ets.required.$invalid"
                  class="text-danger"
                  >Ce champ est requis</small
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- telephone_ets -->
        <b-row>
          <b-col md="12" lg="6">
            <b-form-group>
              <label for=""
                >Téléphone de l'établissement <RequiredStarComponent
              /></label>
              <b-form-input
                id="telephone_ets"
                v-model="form.telephone_ets"
                v-phonenumber-field
                :state="$v.form.telephone_ets.$error ? false : null"
                name="telephone_ets"
                @blur="$v.form.telephone_ets.$touch"
              />
              <div v-if="$v.form.telephone_ets.$error">
                <small
                  v-if="$v.form.telephone_ets.required.$invalid"
                  class="text-danger"
                  >Ce champ est requis</small
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12" lg="6">
            <!-- email_ets -->
            <b-form-group>
              <label for=""
                >Email de l'établissement (en minuscule)<RequiredStarComponent
              /></label>
              <b-form-input
                id="email_ets"
                v-model="form.email_ets"
                :state="$v.form.email_ets.$error ? false : null"
                name="email_ets"
                @blur="$v.form.email_ets.$touch"
              />
              <div v-if="$v.form.email_ets.$error">
                <small
                  v-if="$v.form.email_ets.required.$invalid"
                  class="text-danger"
                  >Ce champ est requis</small
                >
                <small
                  v-if="$v.form.email_ets.email.$invalid"
                  class="text-danger"
                  >Adresse email invalide</small
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <DepartementCommuneArrondissementVillageForm
              :trigger-form-validations="triggerDepartementFormValidations"
              @input="onDepartementFormChange"
            />
          </b-col>

          <b-col md="12" lg="6">
            <!-- password -->
            <b-form-group class="password-field">
              <label for="">Mot de passe <RequiredStarComponent /></label>
              <b-input-group
                class="input-group-merge"
                :class="$v.form.password.$error ? 'is-invalid' : null"
              >
                <b-form-input
                  id="register-password"
                  v-model="form.password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="$v.form.password.$error ? false : null"
                  name="register-password"
                  placeholder="············"
                  @focus="passwordFieldIsFocused = true"
                  @blur="
                    passwordFieldIsFocused = false;
                    $v.form.password.$touch;
                  "
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="
                      passwordFieldType === 'password'
                        ? 'EyeIcon'
                        : 'EyeOffIcon'
                    "
                    class="cursor-pointer"
                    @click="togglePasswordInputType"
                  />
                </b-input-group-append>
              </b-input-group>
              <div
                v-if="passwordFieldIsFocused || $v.form.password.$error"
                class="invalid-feedback password-rules"
              >
                <!-- <small
                  v-if="$v.form.password.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small> -->
                <small
                  v-if="
                    passwordFieldIsFocused || $v.form.password.isValid.$invalid
                  "
                >
                  <span class="label"> Le mot de passe doit contenir :</span>
                  <ul class="list-style-none password-validators-errors">
                    <li
                      :class="{
                        valid: passwordValidators(form.password)
                          .atLeastOneCapLetter,
                      }"
                    >
                      <div class="icons">
                        <i
                          v-if="
                            !passwordValidators(form.password)
                              .atLeastOneCapLetter
                          "
                          class="las la-times-circle"
                        />
                        <i
                          v-if="
                            passwordValidators(form.password)
                              .atLeastOneCapLetter
                          "
                          class="las la-check-circle"
                        />
                      </div>
                      au moins une lettre majuscule
                    </li>
                    <li
                      :class="{
                        valid: passwordValidators(form.password)
                          .atLeastOneMinusLetter,
                      }"
                    >
                      <div class="icons">
                        <i
                          v-if="
                            !passwordValidators(form.password)
                              .atLeastOneMinusLetter
                          "
                          class="las la-times-circle"
                        />
                        <i
                          v-if="
                            passwordValidators(form.password)
                              .atLeastOneMinusLetter
                          "
                          class="las la-check-circle"
                        />
                      </div>
                      au moins une lettre minuscule
                    </li>
                    <li
                      :class="{
                        valid: passwordValidators(form.password)
                          .atLeastOneDigits,
                      }"
                    >
                      <div class="icons">
                        <i
                          v-if="
                            !passwordValidators(form.password).atLeastOneDigits
                          "
                          class="las la-times-circle"
                        />
                        <i
                          v-if="
                            passwordValidators(form.password).atLeastOneDigits
                          "
                          class="las la-check-circle"
                        />
                      </div>
                      au moins un chiffre
                    </li>
                    <li
                      :class="{
                        valid: passwordValidators(form.password)
                          .atLeastOneSpecialChar,
                      }"
                    >
                      <div class="icons">
                        <i
                          v-if="
                            !passwordValidators(form.password)
                              .atLeastOneSpecialChar
                          "
                          class="las la-times-circle"
                        />
                        <i
                          v-if="
                            passwordValidators(form.password)
                              .atLeastOneSpecialChar
                          "
                          class="las la-check-circle"
                        />
                      </div>
                      au moins un caractère special (@#$%^&+=)
                    </li>
                    <li
                      :class="{
                        valid: passwordValidators(form.password).minimumLength,
                      }"
                    >
                      <div class="icons">
                        <i
                          v-if="
                            !passwordValidators(form.password).minimumLength
                          "
                          class="las la-times-circle"
                        />
                        <i
                          v-if="passwordValidators(form.password).minimumLength"
                          class="las la-check-circle"
                        />
                      </div>
                      au moins 8 caractères
                    </li>
                  </ul>
                </small>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12" lg="6">
            <!-- confirm password -->
            <b-form-group>
              <label for=""
                >Confirmer le mot de passe <RequiredStarComponent
              /></label>
              <b-input-group
                class="input-group-merge"
                :class="$v.form.confirmPassword.$error ? 'is-invalid' : null"
              >
                <b-form-input
                  id="register-confirm-password"
                  v-model="form.confirmPassword"
                  name="register-confirm-password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="$v.form.confirmPassword.$error ? false : null"
                  placeholder="············"
                  @blur="$v.form.confirmPassword.$touch"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="
                      passwordFieldType === 'password'
                        ? 'EyeIcon'
                        : 'EyeOffIcon'
                    "
                    class="cursor-pointer"
                    @click="togglePasswordInputType"
                  />
                </b-input-group-append>
              </b-input-group>
              <div v-if="$v.form.confirmPassword.$error">
                <small
                  v-if="$v.form.confirmPassword.isValid.$invalid"
                  class="text-danger"
                  >Les mots de passe ne sont pas identiques</small
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              v-for="(fileInput, index) in piecesJointesList"
              :key="index"
              :label-for="fileInput.label + index"
              :label="fileInput.label"
              class=""
            >
              <!-- Styled -->
              <b-form-file
                v-model="piecesJointesList[index].value"
                placeholder="Veuillez choisir un fichier"
                drop-placeholder="Déposez ici..."
                accept="application/pdf"
                :state="
                  !isValidFile(piecesJointesList[index].value) ? false : null
                "
              />
              <div>
                <small
                  v-if="!isValidFile(piecesJointesList[index].value)"
                  class="text-danger"
                  >La taille de ce fichier dépasse 1Mo
                </small>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <hr class="my-2" />
        <b-card-header class="d-flex justify-content-between">
          <h3>Informations concernant le promoteur</h3>
        </b-card-header>
        <b-row>
          <b-col md="12" lg="4">
            <!-- nom_promoteur -->
            <b-form-group>
              <label for=""
                >Nom du promoteur de l'établissement <RequiredStarComponent
              /></label>
              <b-form-input
                id="nom_promoteur"
                v-model="form.nom_promoteur"
                :state="$v.form.nom_promoteur.$error ? false : null"
                name="nom_promoteur"
                @blur="$v.form.nom_promoteur.$touch"
              />
              <div v-if="$v.form.nom_promoteur.$error">
                <small
                  v-if="$v.form.nom_promoteur.required.$invalid"
                  class="text-danger"
                  >Ce champ est requis</small
                >
              </div>
            </b-form-group>
          </b-col>

          <b-col md="12" lg="4">
            <!-- prenoms_promoteur -->
            <b-form-group>
              <label for=""
                >Prénom(s) du promoteur de l'établissement
                <RequiredStarComponent
              /></label>
              <b-form-input
                id="prenoms_promoteur"
                v-model="form.prenoms_promoteur"
                :state="$v.form.prenoms_promoteur.$error ? false : null"
                name="prenoms_promoteur"
                @blur="$v.form.prenoms_promoteur.$touch"
              />
              <div v-if="$v.form.prenoms_promoteur.$error">
                <small
                  v-if="$v.form.prenoms_promoteur.required.$invalid"
                  class="text-danger"
                  >Ce champ est requis</small
                >
              </div>
            </b-form-group>
          </b-col>

          <b-col md="12" lg="4">
            <!-- telephone_promoteur -->
            <b-form-group>
              <label for=""
                >Téléphone du promoteur de l'établissement
                <RequiredStarComponent
              /></label>
              <b-form-input
                id="telephone_promoteur"
                v-model="form.telephone_promoteur"
                v-phonenumber-field
                :state="$v.form.telephone_promoteur.$error ? false : null"
                name="telephone_promoteur"
                autocomplete="off"
                @blur="$v.form.telephone_promoteur.$touch"
              />
              <div v-if="$v.form.telephone_promoteur.$error">
                <small
                  v-if="$v.form.telephone_promoteur.required.$invalid"
                  class="text-danger"
                  >Ce champ est requis</small
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" lg="6">
            <!-- email_promoteur -->
            <b-form-group>
              <label for=""
                >Email du promoteur de l'établissement (en
                minuscule)<RequiredStarComponent
              /></label>
              <b-form-input
                id="email_promoteur"
                v-model="form.email_promoteur"
                name="email_promoteur"
                @blur="$v.form.email_promoteur.$touch"
              />
              <div v-if="$v.form.email_promoteur.$error">
                <small
                  v-if="$v.form.email_promoteur.required.$invalid"
                  class="text-danger"
                >
                  Ce champ est requis</small
                >
                <small
                  v-if="$v.form.email_promoteur.email.$invalid"
                  class="text-danger"
                  >Adresse email invalide</small
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12" lg="6">
            <b-form-group
              label="Numéro NPI du promoteur de l'établissement"
              label-for="cpi_npi_promoteur"
            >
              <b-form-input
                id="npi_promoteur"
                v-model="form.cpi_npi_promoteur"
                v-digits-only="{ length: 10 }"
                :state="$v.form.cpi_npi_promoteur.$error ? false : null"
                name="npi_promoteur"
                @blur="$v.form.cpi_npi_promoteur.$touch"
              />
              <div v-if="$v.form.cpi_npi_promoteur.$error">
                <small
                  v-if="$v.form.cpi_npi_promoteur.$invalid"
                  class="text-danger"
                  >Ce champs doit avoir 10 caractères</small
                >
              </div>
            </b-form-group>

            <!-- <b-form-group label-for="cpi_npi_promoteur">
              <template v-slot:label>
                <span>
                  Numéro NPI du promoteur de l'établissement (exemple :
                  3880872856)
                </span>
              </template>
              <b-input-group
                :class="
                  have_check_npi && !npi_value_valid && 'border border-danger'
                "
              >
                <b-form-input
                  id="npi_representant_legal"
                  v-model="form.cpi_npi_promoteur"
                  v-digits-only="{ length: 10 }"
                  :state="$v.form.cpi_npi_promoteur.$error ? false : null"
                  name="npi_representant_legal"
                  @blur="$v.form.cpi_npi_promoteur.$touch"
                  @change="change_npi"
                />
                <b-input-group-append is-text>
                  <b-spinner v-if="isLoading_check_npi" small />
                  <i
                    v-else
                    class="las"
                    :class="
                      npi_value_valid
                        ? 'la-check iconGreen'
                        : 'la-times iconRed'
                    "
                  />
                </b-input-group-append>
              </b-input-group>
           
            </b-form-group> -->
          </b-col>
          <b-col md="6">
            <b-form-group>
              <template #label>
                Nationalité du promoteur<RequiredStarComponent class="mr-2" />
              </template>

              <v-select
                v-model="form.nationalitePromoteur"
                type="text"
                :options="pays"
                label="name"
                placeholder="Nationalité du promoteur"
                :class="$v.form.type_ets.$error ? 'invalid-input' : ''"
                @close="onNationalityInputDirty"
              />
              <div v-if="$v.form.nationalitePromoteur.$error">
                <small
                  v-if="$v.form.nationalitePromoteur.required.$invalid"
                  class="text-danger"
                  >Ce champ est requis</small
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- pieces_jointe -->
        <!-- <label for="">Pieces jointes (Obligatoire): Carte d'identité ou Passport ou CIP
            ou NPI ou Acte de naissance sécurisé ou Permis de conduire
          </label>
          <br>
          <small>- 5 fichiers au maximum <br>
            - 1 Mo par fichier au maximum</small>
          <br>
          <small>Formats acceptés: Images(.png, .jpg), PDF</small> -->

        <!-- recaptcha -->
        <div v-if="false" class="mb-1">
          <!-- Styled -->
          <RecaptchaWidget @onRecaptchaChange="onRecaptchaChange" />
          <small
            v-if="$v.recaptchaResponse.isValid.$invalid"
            class="text-danger"
            >Veuillez résoudre le recaptcha pour continuer</small
          >
        </div>

        <!-- submit button -->
        <b-button variant="primary" block type="submit">
          <b-spinner v-if="isLoading" small />
          S'inscrire
        </b-button>
      </b-form>
    </b-card>

    <!-- modal for notification -->
    <b-modal
      id="modal-info"
      ref="modal-info"
      hide-backdrop
      ok-only
      no-close-on-backdrop
      content-class="shadow"
      title="Informations Importantes"
      ok-title="Ok"
      cancel-title="Retour Accueil"
      cancel-variant="outline-secondary"
    >
      <b-card-text>
        <span
          >Avant de commencer à remplir le formulaire, veuillez préparer les
          pièces jointes justificatives suivantes:</span
        >
        <br />
        <br />
        <ol>
          <li v-for="(pieceJointe, index) in piecesJointesList" :key="index">
            <strong> {{ pieceJointe.label }}</strong>
          </li>
        </ol>
        Ces documents doivent respecter les conditions suivantes:
        <ul>
          <li>Etre au format <strong>PDF</strong></li>
          <li>
            Avoir une taille maximale de <strong>1 Mo (par fichier)</strong>
          </li>
        </ul>
        <!-- <span>Vous pouvez ajouter 5 fichiers </span> -->
      </b-card-text>
      <!-- <b-card-text>
        Candy oat cake topping topping chocolate cake. Icing pudding jelly beans I love chocolate carrot cake wafer
        candy canes. Biscuit croissant fruitcake bonbon soufflé.
      </b-card-text> -->
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BModal,
  BFormRadioGroup,
  BFormRadio,
  BCardHeader,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import AppLogo from "@/components/AppLogo.vue";
import RecaptchaWidget from "@/components/RecaptchaWidget.vue";
import RequiredStarComponent from "@/components/RequiredStarComponent.vue";
import localstorageService from "@/services/localstorage/localstorage.service";
import passwordValidators from "@/helpers/password-validators";
import DepartementCommuneArrondissementVillageForm from "@/components/DepartementCommuneArrondissementVillageForm.vue";

// store module and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import secteursStoreModule from "@/store/secteurs";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

import { $themeConfig } from "@themeConfig";
import utilsService from "@/services/utils/utils.service";
import { xml2json } from "xml-js";
const lowercaseEmail = (value) => {
  if (value !== value.toLowerCase()) {
    return false;
  }
  return true;
};

export default {
  page() {
    return {
      title: () => this.title,
      meta: [{ name: "description", content: $themeConfig.app.description }],
    };
  },
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardHeader,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    // validations
    AppLogo,
    DepartementCommuneArrondissementVillageForm,
    vSelect,
    RecaptchaWidget,
    RequiredStarComponent,
    BSpinner,
    BRow,
    BCol,
    BModal,
    BFormRadioGroup,
    BFormRadio,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "secteurs", module: secteursStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return {
      requiredStoreModules,
      passwordValidators,
      $v: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      title: "Inscription des établissements des industries touristiques",
      piecesJointesList: [
        { label: "Agrément/Licence", value: null },
        { label: "Registre de commerce", value: null },
        { label: "Pièce d'identité du promoteur", value: null },
      ],
      pays: [],
      triggerDepartementFormValidations: false,
      form: {
        nom_ets: "",
        type_ets: "",
        sous_type_ets: { radio: "", input: "" },
        adresse_ets: "",
        telephone_ets: "",
        email_ets: "",
        nom_promoteur: "",
        prenoms_promoteur: "",
        telephone_promoteur: "",
        email_promoteur: "",
        cpi_npi_promoteur: "",
        nationalitePromoteur: "",
        departement: "",
        commune: "",
        arrondissement: "",
        qtier: "",
        pieces_jointes: [],
        rccm: "",
        ifu: "",
        mumeroAgrement: "",
        password: "",
        confirmPassword: "",
      },
      typesEts: [],
      passwordFieldType: "password",

      recaptchaResponse: null,

      isLoading: false,
      passwordFieldIsFocused: false,
      isRegisteredSuccessfully: false,
      rccm_string_valid: false,
      have_check_rccm: false,
      rccm_value_valid: false,
      isLoading_check_rccm: false,
      isLoading_check_npi: false,
      have_check_npi: false,
      npi_value_valid: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    selectedTypeEtsSubTypes() {
      if (this.selectedTypeEtsHasSubTypes) return this.form.type_ets.sousTypes;
      return [];
    },
    selectedTypeEtsHasSubTypes() {
      return (
        this.form.type_ets &&
        this.form.type_ets.sousTypes &&
        Array.isArray(this.form.type_ets.sousTypes) &&
        this.form.type_ets.sousTypes.length > 0
      );
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.pays = utilsService.getListCountries();
      },
    },
    "form.rccm": {
      immediate: false,
      deep: true,
      handler(val, old) {
        this.rccm_string_valid = false;
        const rccmRegex = /^[A-Z0-9]{2}[-/][A-Z0-9]{3}[-/][A-Z0-9\s]+$/;
        if (rccmRegex.test(val)) {
          // La chaîne de caractères respecte le format
          this.rccm_string_valid = true;
        } else {
          // La chaîne de caractères ne respecte pas le format
          this.rccm_string_valid = false;
        }
      },
    },
    passwordFieldIsFocused: {
      handler(val, old) {
        this.$v.form.password.$validate();
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },

  validations() {
    return {
      form: {
        ifu: {
          isValid(value) {
            if (value && value.toString().length === 13) return true;
            if (!value) return true;
            return false;
          },
        },
        nom_ets: { required },
        type_ets: { required },
        ...(this.selectedTypeEtsHasSubTypes && {
          sous_type_ets: {
            radio: { required },
            ...(this.form.sous_type_ets &&
              this.form.sous_type_ets.radio &&
              this.form.sous_type_ets.radio.requireManualInput && {
                input: {
                  required,
                },
              }),
          },
        }),
        adresse_ets: { required },
        telephone_ets: { required },
        email_ets: { required, email, lowercaseEmail },
        nom_promoteur: { required },
        prenoms_promoteur: { required },
        telephone_promoteur: { required },
        email_promoteur: { required, email, lowercaseEmail },
        cpi_npi_promoteur: {
          isValid(value) {
            if (value && value.toString().length === 10) return true;
            if (!value) return true;
            return false;
          },
        },
        nationalitePromoteur: { required },
        departement: { required },
        commune: { required },
        arrondissement: { required },
        qtier: { required },
        // pieces_jointes: { required },
        // rccm: { required },
        // ifu: { required },
        // numero_agrement: { required },
        password: {
          required,
          isValid(value) {
            return this.passwordValidators(value).isValid;
          },
        },
        confirmPassword: {
          isValid: (val) => val && val === this.form.password,
        },
      },
    };
  },
  mounted() {
    this.getListTypesEtablissement();
    // this.showModal('modal-info')
    this.$refs["modal-info"].show();
  },
  methods: {
    ...mapActions("secteurs", {
      fetchSecteurs: "fetchSecteurs",
    }),
    ...mapActions("etablissements", {
      createEtablissement: "createEtablissement",
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    ...mapActions("imagePuzzleSliderCaptcha", { showSliderCaptcha: "show" }),
    // ----------------------------------------------------------------------
    onTypeEtsInputDirty() {
      this.$v.form.type_ets.$touch();
      if (this.$v.form.sous_type_ets) {
        this.$v.form.sous_type_ets.radio.$touch();

        this.$v.form.sous_type_ets.input &&
          this.$v.form.sous_type_ets.input.$touch();
      }
    },
    onNationalityInputDirty() {
      this.$v.form.type_ets.$touch();
      if (this.$v.form.sous_type_ets) {
        this.$v.form.sous_type_ets.radio.$touch();

        this.$v.form.sous_type_ets.input &&
          this.$v.form.sous_type_ets.input.$touch();
      }
    },
    getListTypesEtablissement() {
      this.action_getListTypesEtablissement()
        .then((res) => {
          console.log("typeets", res);
          this.typesEts = (res.data.resources || []).map((e) => {
            const { sousTypes } = e;
            return {
              ...e,
              sousTypes: [...sousTypes].map((soustype) => ({
                value: soustype,
                text: soustype,
                ...(soustype === "Autres" && {
                  requireManualInput: true,
                  input: "",
                  code: "autres_sous_types",
                }),
              })),
            };
          });
        })
        .catch((err) => {});
    },
    onRecaptchaChange(recaptchaEvent) {
      if (recaptchaEvent && recaptchaEvent.type === "success") {
        this.recaptchaResponse = recaptchaEvent;
      } else {
        this.recaptchaResponse = null;
      }
    },
    togglePasswordInputType() {
      // eslint-disable-next-line no-unused-expressions
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    onDepartementFormChange(evt) {
      this.form.departement = evt.departement;
      this.form.commune = evt.commune;
      this.form.arrondissement = evt.arrondissement;
      this.form.qtier = evt.qtier;
    },
    isValidFile(file) {
      if (file && file.size && file.size > 5000000) {
        return false;
      }
      return true;
    },

    change_rccm() {
      if (!this.rccm_string_valid) return;
      if (this.isLoading_check_rccm) return;
      this.isLoading_check_rccm = true;

      this.$store
        .dispatch("utils/checkRCCM", {
          numeroRccm: this.form.rccm,
        })
        .then((response) => {
          this.have_check_rccm = true;
          this.isLoading_check_rccm = false;
          if (response.data && response.data.length === 1) {
            // console.log("utils/checkRCCM response::: 🟢🟢🟢", response);
            const data = response.data[0];
            if (data.numerorccm === this.form.rccm) {
              this.rccm_value_valid = true;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Numéro RCCM valide",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          } else {
            this.rccm_value_valid = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  "Contrôl échoué ou numéro RCCM invalide. Veuillez vous assurer de renseigner le bon numéro. ",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          console.log("utils/checkRCCM error::: ⭕️", error);
          this.isLoading_check_rccm = false;
          this.rccm_value_valid = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Contrôl échoué ou numéro RCCM invalide. Veuillez vous assurer de renseigner le bon numéro. ",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    change_npi() {
      if (this.isLoading_check_npi) return;
      this.isLoading_check_npi = true;
      this.$store
        .dispatch("utils/checkNPI", {
          numeroNpi: this.form.cpi_npi_promoteur,
        })
        .then((response) => {
          this.have_check_npi = false;
          this.isLoading_check_npi = false;
          let json = xml2json(response.data, { spaces: 2, compact: true });
          json = JSON.parse(json);
          const final_object =
            json["soapenv:Envelope"]["soapenv:Body"][
              "ns:CITIZEN_BY_NPI_LTEResponse"
            ];
          // console.log('utils/checkNPI response::: ⭕️⭕️⭕️', final_object)

          if (final_object && final_object.LAST_NAME) {
            this.npi_value_valid = true;
            this.isLoading_check_npi = false;
            if (final_object.LAST_NAME._text) {
              this.form.nom_representant = final_object.LAST_NAME._text;
              // this.matching_data.npi.lastname = this.form.nom_representant
            }
            if (final_object.FIRST_NAME._text) {
              this.form.prenoms_representant = final_object.FIRST_NAME._text;
              // this.matching_data.npi.firstname = this.form.prenoms_representant
            }
            if (final_object.PHONE._text) {
              this.form.telephone_representant = final_object.PHONE._text;
            }
            if (final_object.SEX._text === "M") {
              this.form.sexe_representant = "Masculin";
            }
            if (final_object.SEX._text === "F") {
              this.form.sexe_representant = "Féminin";
            }
            if (final_object.EMAIL._text) {
              this.form.email_promoteur = final_object.EMAIL._text;
            }

            if (this.is_UEGT_sector_selected) {
              this.matching_data.pass = false;
              return;
            }
            // contrôle matching RCCM and NPI
            // const matchingPercentageLastname = this.getMatchingPercentage(
            //   this.matching_data.rccm.lastname,
            //   this.matching_data.npi.lastname,
            // )
            // this.matching_result.lastname = matchingPercentageLastname
            if (final_object) {
              // // console.log("utils/checkRCCM response::: 🟢🟢🟢", response);
              if (final_object.NPI._text === this.form.cpi_npi_promoteur) {
                this.npi_value_valid = true;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Numéro NPI valide",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
              }
            }
          } else {
            this.npi_value_valid = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  "Contrôl échoué ou numéro NPI invalide. Veuillez vous assurer de renseigner le bon numéro. ",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          this.isLoading_check_npi = false;
          this.npi_value_valid = false;
          console.log("utils/checkNPI error::: ⭕️", error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Contrôl échoué ou numéro NPI invalide. Veuillez vous assurer de renseigner le bon numéro. ",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    async submitForm() {
      this.isRegisteredSuccessfully = false;
      const isFormCorrect = await this.$v.$validate();
      // console.log(this.$v.form.sous_type_ets)
      if (this.isLoading) return;
      if (!isFormCorrect) {
        this.triggerDepartementFormValidations = true;
        return;
      }
      const files = this.piecesJointesList
        .map((e) => e.value)
        .filter((e) => !!e);
      const allFilesAreValid = files.map((file) => this.isValidFile(file));

      for (let index = 0; index < allFilesAreValid.length; index++) {
        const element = allFilesAreValid[index];
        if (element === false) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title:
                "Vos fichiers ne sont pas au bon format. Veuillez renseigner des fichiers de 10Mo maximum, au format PDF",
              icon: "CheckIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          return;
        }
      }

      // if (!allFilesAreValid) { //allFilesAreValid.length > 0 && !allFilesAreValid[0]
      //   this.$toast({
      //     component: ToastificationContent,
      //     position: 'top-right',
      //     props: {
      //       title: 'Vos fichiers ne sont pas au bon format. Veuillez renseigner des fichiers de 10Mo maximum, au format PDF',
      //       icon: 'CheckIcon',
      //       variant: 'danger',
      //     },
      //   })
      //   return
      // }

      this.triggerDepartementFormValidations = false;
      this.isLoading = true;

      // const sliderCaptchaResponse = await this.showSliderCaptcha()
      // const { isSolved } = sliderCaptchaResponse || {}
      // console.log('sliderCaptchaResponse', isSolved)
      // if (!isSolved) {
      //   this.isLoading = false
      //   return
      // }
      const isNotNull = (val) => val && val.trim() !== "";
      const getValueFromInput = (inputField, fieldType) => {
        let result = "";
        if (fieldType === "radio") {
          result =
            inputField.radio && inputField.radio.requireManualInput
              ? inputField.input
              : inputField.radio?.value;
        } else {
          result = isNotNull(inputField.input)
            ? inputField.input
            : inputField.value;
        }
        return result;
      };
      // console.log('files :>> ', files)
      const formData = new FormData();
      const objectdata = {
        nomEtablissement: this.form.nom_ets,
        typeEtablissement: this.form.type_ets._id,

        sousTypeEtablissement: getValueFromInput(
          this.form.sous_type_ets,
          "radio"
        ),
        adresseEtablissement: this.form.adresse_ets,
        telephoneEtablissement: this.form.telephone_ets,
        emailEtablissement: this.form.email_ets.toLowerCase(),
        password: this.form.password,
        nomPromoteur: this.form.nom_promoteur,
        prenomsPromoteur: this.form.prenoms_promoteur,
        telephonePromoteur: this.form.telephone_promoteur,

        emailPromoteur: this.form.email_promoteur.toLowerCase(),
        npicniPromoteur: this.form.cpi_npi_promoteur,
        nationalitePromoteur: this.form.nationalitePromoteur,
        departement: this.form.departement,
        commune: this.form.commune,
        arrondissement: this.form.arrondissement,
        quartier: this.form.qtier,
        rccm: this.form.rccm,
        ifu: this.form.ifu,
        mumeroAgrement: this.form.mumeroAgrement,
      };

      // console.log('objectdata: ⭕️ ', objectdata)

      const fileprop = "fichiers";
      const filenumber = files.length || 0;

      formData.append("objectdata", JSON.stringify(objectdata));
      formData.append("fileprop", fileprop);
      formData.append("filenumber", `${filenumber}`);

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        formData.append(`filedata${index}`, file);
      }
      this.createEtablissement(formData)
        .then((result) => {
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Inscription reussie",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          setTimeout(() => {
            this.isLoading = false;
            this.isRegisteredSuccessfully = true;
          }, 1000);
          return;
          if (localstorageService.getUserId()) {
            this.$router.replace({ name: "etablissements-list" });
          } else {
            this.$router.replace({ name: "auth-login" });
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    showModal(id) {
      this.$refs[id].show();
    },
  },
};
</script>

<style lang="scss">
// @import '@core/scss/vue/pages/page-auth.scss';
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Parcourir";
}
.invalid-input .vs__dropdown-toggle {
  border-color: #f3706a;
}
// .password-field {
//   position: relative;
//   .invalid-feedback.password-rules {
//     position: absolute;
//     background: white;
//     border: 1px solid;
//     top: -170px;
//     left: 0;
//     z-index: 9;
//     box-shadow: 1px 1px 6px 0px #00000042;
//     border-radius: 5px;
//   }
// }
</style>
