import utilsService from '@/services/http/utils.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async checkIFU({ rootState }, data) {
      const res = await utilsService.checkIFU(data)
      return res
    },
    async checkNPI({ rootState }, data) {
      const res = await utilsService.checkNPI(data);
      return res
    },

    async checkRCCM({ rootState }, data) {
      const res = await utilsService.checkRCCM(data);
      return res
    },
    async sendMail({ rootState }, data) {
      const res = await utilsService.sendMail(data)
      return res
    },
    async getAdminData({ rootState }, data) {
      const res = await utilsService.getAdminData(data)
      return res
    },
  },
}
