import { BaseService } from './base.service'
import axios from 'axios';

export class UtilsService extends BaseService {

  constructor() {
    super()
  }


  checkRCCM({ numeroRccm }) {

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/check-rccm?rccm=${numeroRccm}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  checkNPI({ numeroNpi }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/check-npi?npi=${numeroNpi}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }


  checkIFU({ numeroIfu }) {
    return new Promise((resolve, reject) => {
      this.nodejsAxiosInstance
        .get('https://ifubackend.impots.bj/api/default/searchByIFU/' + numeroIfu)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  sendMail(data) {
    return new Promise((resolve, reject) => {
      this.nodejsAxiosInstance
        .post('/send-mail', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getAdminData(code) {
    const url = 'https://pprodofficial.service-public.bj/api/official/token';
    const ssoUrlCallback = "https://siges-tourisme.gouv.bj/api/v1/admins/login-official";

    const headers = {
      'Authorization': 'Basic bXRjYS1zaWdIczptdGNhLXNpZ2Vz', // Remplacez par votre token
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    const params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('redirect_uri', ssoUrlCallback); // Remplacez par votre URI de redirection
    params.append('code', code); // Remplacez par le code ici

    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(url, params, { headers });
        console.log('Réponse:', response.data);
        resolve(response)
      } catch (error) {
        console.error('Erreur lors de la requête:', error.response ? error.response.data : error.message);
        reject(error)
      }
    })
  }
}

export default new UtilsService()