export default [
  // {
  //   path: '/users/search',
  //   name: 'users-search',
  //   component: () => import('@/views/pages/users/users-search/UserList.vue'),
  // },
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/apps/utilisateurs/administration/ListUtilisateurs.vue'),
    meta: {
      action: 'read',
      resource: 'Public',
    },
  },
  {
    path: '/users/:userId',
    name: 'users-details',
    component: () => import('@/views/apps/utilisateurs/administration/DetailsUtilisateur.vue'),
    meta: {
      action: 'read',
      resource: 'commissaire',
    },
  },

  {
    path: '/api/v1/admins/login-official/:code?',
    name: 'login-administration',
    component: () => import('@/views/pages/authentication/Login-Admin.vue'),
  },

]
