<template>
  <div>
    <validation-observer ref="formRules">
      <!-- head -->
      <!-- <p>test</p> -->
      <div>
        <!-- <b-row> {{infos}}</b-row> -->
        <b-card>
          <b-card-header>
            <b-row no-gutters class="w-100">
              <b-col md="12">
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-popover.hover.focus="
                        '⚪️La fiche statistique mensuelle des hôtels et établissements assimilés est éditable par les promoteurs d’établissements et leurs agents. Elle permet de collecter les données sur les activités menées par les hôtels et établissements assimilés concerné mais aussi des données sur leurs recettes, leur commissions et leurs dépenses.⚪️ La fiche est remplie une fois par mois et est visible par le super utilisateur et les agents du MTCA.'
                      "
                      size="lg"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      title="Information"
                    >
                      <feather-icon icon="BellIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <b-card-img
                  :src="require('@/assets/images/illustration/hébergement.png')"
                  alt="Image"
                  class="rounded-0 my-2"
                />
              </b-col>
              <b-col
                md="10"
                class="d-flex align-items-center justify-content-center"
              >
                <div>
                  <h1 class="m-auto text-center">FICHE STATISTIQUE</h1>
                  <br />
                  <h4 class="m-auto text-center">
                    Hôtels et établissements assimilés
                  </h4>
                </div>
              </b-col>
            </b-row>
          </b-card-header>
          <content-loader
            v-if="isCheckingForLastForm"
            view-box="0 0 400 460"
            :speed="2"
            primary-color="#f3f3f3"
            secondary-color="#ecebeb"
          >
            <circle cx="29" cy="30" r="17" />
            <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
            <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
            <rect x="-7" y="60" rx="2" ry="2" width="408" height="132"
          /></content-loader>
          <b-card-body v-if="!isCheckingForLastForm" id="entete" class="mt-2">
            <FormHeader
              :input-data="initialsValues"
              @onFormSuccess="
                ($event) => {
                  infos = { ...infos, ...$event };
                }
              "
            />
            <!-- <b-row>
              <b-col md="6">
                <b-form-group
                  label-cols-md="3"
                  label="Année"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-select
                      v-model="infos.annee"
                      type="text"
                      :options="annees"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Renseignez l'année en cours"
                      required="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label-cols-md="3"
                  label="Département"
                >
                <b-form-input
                    v-model="infos.departement"
                    type="text"
                    disabled
                />
                </b-form-group>
                <b-form-group
                  label-cols-md="3"
                  label="Arrondissement"
                >
                <b-form-input
                    v-model="infos.arrondissement"
                    type="text"
                    disabled
                />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-cols-md="3"
                  label="Mois"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-select
                      v-model="infos.mois"
                      type="text"
                      :options="mois"
                      label="name"
                      placeholder="Sélectionnez le mois"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label-cols-md="3"
                  label="Commune"
                >
                <b-form-input
                  v-model="infos.arrondissement"
                  type="text"
                  disabled
                />
                </b-form-group>
                <b-form-group
                  label-cols-md="3"
                  label="Quartier/Village"
                >
                <b-form-input
                    v-model="infos.quartier"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row> -->
          </b-card-body>
        </b-card>
      </div>
      <h5 class="d-flex justify-content-end">
        Tout les champs avec la mention<RequiredStarComponent /> sont requis
      </h5>
      <b-row v-if="!isCheckingForLastForm">
        <!-- navigation -->
        <b-col lg="3" class="d-none d-lg-block">
          <div>
            <b-card>
              <b-list-group>
                <!-- <b-list-group-item href="#entete">
                  EN TETE
                </b-list-group-item> -->
                <b-list-group-item href="#renseignementsgeneraux">
                  RENSEIGNEMENTS GÉNÉRAUX
                </b-list-group-item>
                <b-list-group-item href="#capaciteaccueil">
                  CAPACITÉ D'ACCEUIL
                </b-list-group-item>
                <b-list-group-item href="#emplois"> EMPLOIS </b-list-group-item>
                <b-list-group-item href="#expoiltationmensuelle">
                  RENSEIGNEMENTS EXPLOITATION DU MOIS
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </div>
          <div class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              block
              @click="sendToPreview()"
            >
              Aperçu avant enregistrement
            </b-button>
          </div>
        </b-col>
        <!-- body -->
        <b-col lg="9" class="pb-2">
          <!-- RENSEIGNEMENTS GENERAUX  -->
          <div>
            <b-card id="renseignementsgeneraux">
              <b-card-header class="d-flex justify-content-between">
                <h3>RENSEIGNEMENTS GÉNÉRAUX</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <b-row>
                  <!-- Nom de l'établissement -->
                  <b-col md="6">
                    <b-form-group label="Nom de l'établissement">
                      <b-form-input
                        v-model="infos.nomEtablissement"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <!-- type de l'établissement -->
                  <b-col md="6">
                    <b-form-group label="Type d’établissement">
                      <b-form-input
                        v-model="get_sous_type"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Nom du promoteur -->
                  <b-col md="6">
                    <b-form-group label="Nom Promoteur">
                      <b-form-input
                        v-model="infos.nomPromoteur"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Prenoms du promoteur -->
                  <b-col md="6">
                    <b-form-group label="Prénom(s) Promoteur">
                      <b-form-input
                        v-model="infos.prenomsPromoteur"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Nationalité du promoteur -->
                  <b-col md="12">
                    <b-form-group label="Nationalité du Promoteur">
                      <b-form-input
                        v-model="infos.nationalitePromoteur"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <!-- adresse complète -->
                  <b-col md="6">
                    <b-form-group label="Adresse Complète Établissement ">
                      <b-form-input
                        v-model="infos.adresseComplete"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- telephone -->
                <b-row class="mt-2">
                  <b-col md="12">
                    <div>Numéros de Téléphone</div>
                  </b-col>
                  <b-col class="mt-1" md="4">
                    <b-form-group label-cols-md="3" label="Num 1">
                      <b-form-input
                        v-model="infos.telephone1"
                        v-phonenumber-field
                        type="text"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col class="mt-1" md="4">
                    <b-form-group label-cols-md="3" label="Num 2">
                      <b-form-input
                        v-model="infos.telephone2"
                        v-phonenumber-field
                        type="text"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col class="mt-1" md="4">
                    <b-form-group label-cols-md="3" label="Num 3">
                      <b-form-input
                        v-model="infos.telephone3"
                        v-phonenumber-field
                        type="text"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- type établissement -->
                <!-- <b-row class="mt-2" v-if="false">
                  <b-col md="12"> Type Établissement </b-col>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-radio-group
                      v-model="infos.typeEtablissement"
                      :options="typesEts"
                      class="demo-inline-spacing mb-1 ml-1"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                    />
                    <small class="text-danger ml-1">{{ errors[0] }}</small>
                  </validation-provider>

                  <b-col
                    v-if="infos.typeEtablissement == 'Autres'"
                    class=""
                    md="12"
                  >
                    <b-form-group
                      label-cols="5"
                      label-cols-lg="3"
                      label="Type Établissement"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="infos.typeEtablissementAutres"
                          type="text"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row> -->
                <hr />
                <!-- agrément -->
                <b-row class="mt-2">
                  <b-col md="12">
                    <div>
                      Détenez-vous un agrément du Ministère Tourisme
                      ?<RequiredStarComponent class="mr-2" /></div
                  ></b-col>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-form-radio-group
                      v-model="infos.agrementMinistere"
                      :options="agrementOptions"
                      class="demo-inline-spacing mb-1 ml-1"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                    />
                    <small class="text-danger ml-1">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-col
                    v-if="infos.agrementMinistere == 'oui'"
                    class="mt-1"
                    md="10"
                  >
                    <b-form-group
                      label-cols="5"
                      label-cols-lg="3"
                      label="Référence Agrément"
                    >
                      <validation-provider v-slot="{ errors }" rules="required">
                        <b-form-input
                          v-model="infos.referenceAgrement"
                          type="text"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-if="invalideAgrement" class="text-danger"
                          >Agrément invalide</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-else class="mt-1" md="6" />
                </b-row>
                <!-- type etablissement -->
                <!-- <b-row class="mt-2">
                  <b-col md="12">
                    <div>Type Etablissement</div>
                  </b-col>
                    {{ infos.typeEtablissement }} - {{ infos.typeEtablissement.nom }}
                </b-row> -->
                <!-- classement -->
                <b-row class="mt-2">
                  <b-col md="12">
                    <div>
                      Classement de l'établissement de
                      l'hébergement<RequiredStarComponent class="mr-2" /></div
                  ></b-col>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-form-radio-group
                      v-model="infos.classement"
                      :options="classementEtoile"
                      class="demo-inline-spacing mb-1 ml-1"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                    />
                    <small class="text-danger ml-1">{{ errors[0] }}</small>
                  </validation-provider>
                </b-row>
                <!-- réservé à l'admin -->
                <!-- <b-form-group
                  class="mt-2"
                  label-cols-lg="3"
                  label="Réservé à l'administration "
                >
                  <b-form-input
                    v-model="infos.resereAdministration"
                    type="text"
                  />
                </b-form-group> -->
              </b-card-body>
            </b-card>
          </div>

          <!-- CAPACITÉ D'ACCEUIL -->
          <div>
            <b-card id="capaciteaccueil">
              <b-card-header>
                <h3>CAPACITÉ D'ACCEUIL</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <!-- Nombre total de chambres ou unités d'hébergements -->
                <b-form-group>
                  <label
                    for="NbreTotalChambre"
                    class="float-left mr-5 col-form-label col-form-label-md"
                  >
                    Nombre total de chambres ou<br />
                    unités d'hébergements :<RequiredStarComponent class="mr-2"
                  /></label>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    class="float-left w-50"
                  >
                    <b-form-input
                      v-model.number="infos.capacitesAccueil.nombreChambres"
                      v-digits-only="{ length: 16 }"
                      type="number"
                      placeholder="455"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Nombre de chambres ou unités d'hébergement disponibles-->
                <b-form-group>
                  <label
                    for="NbreTotalChambre"
                    class="float-left mr-4 col-form-label col-form-label-md"
                  >
                    Nombre de chambres ou<br />
                    unités d'hébergement<br />
                    disponibles:<RequiredStarComponent class="mr-2"
                  /></label>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    class="ml-3 float-left w-50"
                  >
                    <b-form-input
                      v-model.number="
                        infos.capacitesAccueil.nombreChambresDisponibles
                      "
                      v-digits-only="{ length: 16 }"
                      type="number"
                      placeholder="545"
                      @input="
                        calculateTaux(
                          infos.capacitesAccueil.nombreChambresDisponibles
                        )
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- Nombre total de places - lits-->
                <b-form-group>
                  <label
                    for="NbreTotalChambre"
                    class="float-left mr-5 col-form-label col-form-label-md"
                  >
                    Nombre total de places <br />- lits:<RequiredStarComponent
                      class="mr-2"
                  /></label>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    class="ml-3 float-left w-50"
                  >
                    <b-form-input
                      v-model.number="infos.capacitesAccueil.nombreLits"
                      v-digits-only="{ length: 16 }"
                      type="number"
                      placeholder="644"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- Nombre total de places - lits disponible-->
                <b-form-group>
                  <label
                    for="NbreTotalChambre"
                    class="float-left mr-4 col-form-label col-form-label-md"
                  >
                    Nombre total de places - <br />lits
                    disponibles:<RequiredStarComponent class="mr-2"
                  /></label>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    class="ml-3 float-left w-50"
                  >
                    <b-form-input
                      v-model.number="
                        infos.capacitesAccueil.nombreLitsDisponible
                      "
                      v-digits-only="{ length: 16 }"
                      type="number"
                      placeholder="546"
                      :min="infos.capacitesAccueil.nombreLits"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card-body>
            </b-card>
          </div>

          <!-- EMPLOIS  -->
          <div>
            <b-card id="emplois">
              <b-card-header class="d-flex justify-content-between">
                <h3>EMPLOIS</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <!-- employés salariés -->
                <div class="table-responsive text-center">
                  <h5>Nombre d'employés salariés<RequiredStarComponent /></h5>
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" rowspan="2">
                          Nombre d'employés salariés
                        </th>
                        <th scope="col" colspan="2">Hommes</th>
                        <th scope="col" colspan="2">Femmes</th>
                        <th scope="col" colspan="2">Total</th>
                      </tr>
                      <tr>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nationaux</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.hommeTempspleinNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .hommeTempspartielNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.femmeTempspleinNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .femmeTempspartielNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinNationaux +
                                infos.employesSalaries.femmeTempspleinNationaux
                            )
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesSalaries.hommeTempspartielNationaux +
                            infos.employesSalaries.femmeTempspartielNationaux
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Étrangers</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.hommeTempspleinEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .hommeTempspartielEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.femmeTempspleinEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .femmeTempspartielEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinEtrangers +
                                infos.employesSalaries.femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .hommeTempspartielEtrangers +
                                infos.employesSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Total</th>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinNationaux +
                                infos.employesSalaries.hommeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .hommeTempspartielNationaux +
                                infos.employesSalaries
                                  .hommeTempspartielEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.femmeTempspleinNationaux +
                                infos.employesSalaries.femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .femmeTempspartielNationaux +
                                infos.employesSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinNationaux +
                                infos.employesSalaries
                                  .femmeTempspleinNationaux +
                                infos.employesSalaries
                                  .hommeTempspleinEtrangers +
                                infos.employesSalaries.femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .hommeTempspartielNationaux +
                                infos.employesSalaries
                                  .femmeTempspartielNationaux +
                                infos.employesSalaries
                                  .hommeTempspartielEtrangers +
                                infos.employesSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- nouveeaux employés du mois  -->
                <div class="table-responsive text-center mt-3">
                  <!-- <h5>Nombre de nouveaux emplois créés au cours du mois</h5> -->
                  <h5>
                    Nombre de nouveaux emplois créés au cours du
                    mois<RequiredStarComponent />
                  </h5>
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Salariés recrutés au cours du mois</th>
                        <th scope="col">Hommes</th>
                        <th scope="col">Femmes</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nombre</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="infos.nouveauEmplois.hommes"
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="infos.nouveauEmplois.femmes"
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            infos.nouveauEmplois.hommes +
                            infos.nouveauEmplois.femmes
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- employés non salariés -->
                <div class="table-responsive text-center mt-3">
                  <h5>
                    Nombre d'employés non salariés<RequiredStarComponent />
                  </h5>
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" rowspan="2">
                          Nombre d'employés non salariés
                        </th>
                        <th scope="col" colspan="2">Hommes</th>
                        <th scope="col" colspan="2">Femmes</th>
                        <th scope="col" colspan="2">Total</th>
                      </tr>
                      <tr>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nationaux</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .hommeTempspleinNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .hommeTempspartielNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .femmeTempspleinNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .femmeTempspartielNationaux
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspleinNationaux
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspartielNationaux
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Étrangers</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .hommeTempspleinEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .hommeTempspartielEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .femmeTempspleinEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .femmeTempspartielEtrangers
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspleinEtrangers +
                                infos.employesNonSalaries
                                  .femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspartielEtrangers +
                                infos.employesNonSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Total</th>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .hommeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .hommeTempspartielEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .femmeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .femmeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .hommeTempspleinEtrangers +
                                infos.employesNonSalaries
                                  .femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .hommeTempspartielEtrangers +
                                infos.employesNonSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
            </b-card>
          </div>

          <!-- RENSEIGNEMENTS EXPLOITATION DU MOIS  -->
          <div>
            <b-card id="expoiltationmensuelle">
              <b-card-header class="d-flex justify-content-between">
                <h3>RENSEIGNEMENTS D'EXPLOITATION DU MOIS</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <!-- Chiffre d'affaire global  -->

                <span
                  ><strong
                    >Chiffre d'affaire global (FCFA)<RequiredStarComponent /></strong
                ></span>

                <b-col md="12">
                  <div class="table-responsive text-center mt-1">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Produits</th>
                          <th>Chiffre d'affaires</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">
                            Vente de chambres ou unités d'hébergement
                          </th>
                          <td>
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.chiffreAffaireDetails.venteChambre
                                "
                                v-digits-only="{ length: 16 }"
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            Restauration et consommation de boissons
                          </th>
                          <td>
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.chiffreAffaireDetails.restauConsommation
                                "
                                v-digits-only="{ length: 16 }"
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            Location salles de conférences ou réunions
                          </th>
                          <td>
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.chiffreAffaireDetails.locationSalle
                                "
                                v-digits-only="{ length: 16 }"
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Autres</th>
                          <td>
                            <!-- <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                              > -->
                            <b-form-input
                              v-model.number="
                                infos.chiffreAffaireDetails.autres
                              "
                              v-digits-only="{ length: 16 }"
                              type="number"
                              min="0"
                            />
                            <!-- <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider> -->
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Total</th>
                          <td>
                            {{
                              normalizeNumericValue(
                                infos.chiffreAffaireDetails.venteChambre +
                                  infos.chiffreAffaireDetails
                                    .restauConsommation +
                                  infos.chiffreAffaireDetails.locationSalle +
                                  infos.chiffreAffaireDetails.autres
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>

                <!-- Charges salariales  -->
                <b-form-group>
                  <label
                    for="NbreTotalChambre"
                    class="float-left col-form-label col-form-label-md"
                    >Charges salariales (FCFA)<RequiredStarComponent
                      class="mr-1"
                  /></label>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    class="float-left w-88"
                  >
                    <b-form-input
                      v-model.number="infos.chargesSalariales"
                      v-digits-only="{ length: 16 }"
                      type="number"
                      min="0"
                      placeholder="Veuillez saisir le montant des charges salariales (FCFA)"
                      class=" "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- Autres Dépenses  -->
                <b-form-group>
                  <label
                    for="NbreTotalChambre"
                    class="float-left col-form-label col-form-label-md"
                    >Autres Dépenses (FCFA)<RequiredStarComponent class="mr-2"
                  /></label>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    class="float-left w-88"
                  >
                    <b-form-input
                      v-model.number="infos.autresDepense"
                      v-digits-only="{ length: 16 }"
                      type="number"
                      min="0"
                      placeholder="Veuillez saisir le montant des autres dépenses"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- Nombre d'arrivées  -->
                <b-form-group>
                  <label
                    for="NbreTotalChambre"
                    class="float-left col-form-label col-form-label-md"
                    >Nombre d'arrivées <RequiredStarComponent class="mr-5"
                  /></label>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    class="float-left w-88"
                  >
                    <b-form-input
                      v-model.number="infos.nombreArrives"
                      v-digits-only
                      type="number"
                      min="0"
                      placeholder="Veuillez saisir le nombre d'arrivées"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- Nombre de nuités  -->
                <b-form-group>
                  <label
                    for="NbreTotalChambre"
                    class="float-left col-form-label col-form-label-md"
                    >Nombre de nuitées<RequiredStarComponent class="mr-5"
                  /></label>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    class="float-left w-88"
                  >
                    <b-form-input
                      v-model.number="infos.nombreNuites"
                      v-digits-only="{ length: 16 }"
                      type="number"
                      min="0"
                      placeholder="Veuillez saisir le nombre de nuitées"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- Taux d'occupations mensuel chambre*  -->
                <b-form-group>
                  <label
                    for="NbreTotalChambre"
                    class="float-left col-form-label col-form-label-md"
                    >Taux d’occupation brut<br />
                    des chambre ou <br />unités d’hébergements
                    (%)<RequiredStarComponent class="mr-1"
                  /></label>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    class="float-left w-88"
                  >
                    <b-form-input
                      v-model.number="
                        infos.tauxOccuptationMensuelChambresParChambre
                      "
                      v-digits-only="{ length: 16 }"
                      type="number"
                      min="0"
                      disabled
                      placeholder="Veuillez saisir le taux d’occupation brut des chambres ou unités d’hébergements"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!--   <b-form-group>
                  <label
                    for="NbreTotalChambre"
                    class="float-left col-form-label col-form-label-md mr-3"
                    >Taux d’occupation brut <br />par place lit (%)
                    <RequiredStarComponent class="mr-2"
                  /></label>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    class="float-left w-88"
                  >
                    <b-form-input
                      v-model.number="
                        infos.tauxOccuptationMensuelChambresParLit
                      "
                      type="number"
                      v-digits-only="{ length: 16 }"
                      min="0"
                      placeholder="Veuillez saisir le taux d’occupation brut par place lit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                -->
              </b-card-body>
            </b-card>
          </div>

          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="col-xl-2"
              @click="sendToPreview()"
            >
              Aperçu avant enregistrement
            </b-button>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <!-- <tips-modal-create-from-last-record :canShow="showCreateFromLastModal"></tips-modal-create-from-last-record> -->
    <!-- <TipsModalCreateFromLastRecord /> -->
    <b-modal
      :ref="tipsModalRef"
      scrollable
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Créer un formulaire"
      size="lg"
    >
      <!-- <b-card-text>
        <span>We've added the utility class</span>
        <code>'shadow'</code>
        <span>to the modal content for added effect.</span>
      </b-card-text> -->
      <b-row>
        <b-col cols="12">
          <b-card>
            <h3 class="text-primary">
              Créer a partir du dernier formulaire enregistré
            </h3>
            <p class="my-1">
              Vous avez la possibilté de récupérer les données du dernier
              formulaire que vous avez enregistré pour remplir ce nouveau
              formulaire.
            </p>
            <b-button
              variant="outline-primary"
              class="float-right"
              @click="fillFormWithLastRecord"
            >
              Continuer
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card>
            <h3 class="text-primary">Créer a partir d'un formulaire vierge</h3>
            <p class="my-1">
              Vous avez la possibilté de remplir le formulaire de zéro.
            </p>

            <b-button
              variant="outline-primary"
              class="float-right"
              @click="hideModal"
            >
              Continuer
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardImg,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
  BInputGroupPrepend,
  BInputGroup,
  VBPopover,
  BModal,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ContentLoader } from "vue-content-loader";
import localstorageService from "@/services/localstorage/localstorage.service";
import TipsModalCreateFromLastRecord from "@/components/TipsModalCreateFromLastRecord.vue";
import utilsService from "@/services/utils/utils.service";
import RequiredStarComponent from "@/components/RequiredStarComponent.vue";

import moment from "moment";
// imports for validation
// const store = require('store')

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import utilsStoreModule from "@/store/utils";
import formulaireStoreModule from "@/store/formulaire";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";
import FormHeader from "../components/FormHeader.vue";

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardImg,
    // BCardText,
    // BForm,
    BModal,
    BInputGroupPrepend,
    BInputGroup,
    ContentLoader,
    TipsModalCreateFromLastRecord,
    FormHeader,
    RequiredStarComponent,
  },
  directives: {
    Ripple,
    "b-popover": VBPopover,
  },
  mixins: [heightTransition], //
  props: {
    etablissementDetails: null,
    apiRoutePrefixe: null,
  },
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "utils", module: utilsStoreModule },
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      isFillingForm: false,
      formInput: this.$route.params.data,
      lastRecordedForm: null,
      tipsModalRef: new Date().getTime(),
      showCreateFromLastModal: true,
      currentUserId: localstorageService.getUserId(),
      isCheckingForLastForm: true,
      formType: "formstatagence",
      email,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      annees: [],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],
      typesEts: [
        { text: "Hôtel", value: "Hôtel" },
        { text: "Motel", value: "Motel" },
        { text: "Auberge", value: "Auberge" },
        { text: "Pension de famille", value: "Pension de famille" },
        { text: "Appartements meublés", value: "Appartements meublés" },
        { text: "Hôtels à appartements", value: "Hôtels à appartements" },
        { text: "Autres", value: "Autres" },
      ],
      agrementOptions: ["non", "oui"],
      classementEtoile: [
        { text: "Non classé", value: "Non classé" },
        { text: "1 étoile", value: "1 étoile" },
        { text: "2 étoiles", value: "2 étoiles" },
        { text: "3 étoiles", value: "3 étoiles" },
        { text: "4 étoiles", value: "4 étoiles" },
        { text: "5 étoiles", value: "5 étoiles" },
      ],
      autofillableFields: [
        {
          name: "annee",
        },
        {
          name: "departement",
        },
        {
          name: "commune",
        },
        {
          name: "arrondissement",
        },
        {
          name: "quartier",
        },
        {
          name: "nomEtablissement",
        },
        // {
        //   name: "numIFU",
        // },
        {
          name: "nomPromoteur",
        },
        {
          name: "prenomsPromoteur",
        },
        // {
        //   name: "emailPromoteur",
        // },
        {
          name: "nationalitePromoteur",
        },
        {
          name: "adresseComplete",
          key: "adresseEtablissement",
        },
        {
          name: "telephone1",
          key: "telephoneEtablissement",
        },
        {
          name: "telephone2",
        },
        {
          name: "telephone3",
        },
        {
          name: "typeEtablissement",
        },
        {
          name: "agrementMinistere",
        },
        {
          name: "classement",
        },
        {
          name: "referenceAgrement",
        },
      ],
      initialsValues: {},
      infos: {
        annee: "",
        mois: "",
        departement: "",
        commune: "",
        arrondissement: "",
        quartier: "",
        nomEtablissement: "",
        // numIFU: "",
        nomPromoteur: "",
        prenomsPromoteur: "",
        nationalitePromoteur: "",
        adresseComplete: "",
        telephone1: "",
        telephone2: "",
        telephone3: "",
        typeEtablissement: "",
        agrementMinistere: "",
        referenceAgrement: "",
        classement: "",
        // capacité d'acceuil
        capacitesAccueil: {
          nombreChambres: null,
          nombreChambresDisponibles: null,
          nombreLits: null,
          nombreLitsDisponible: null,
          // nombreVehiculesJour: null,
        },
        // employés
        employesSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },
        nouveauEmplois: {
          hommes: null,
          femmes: null,
        },
        employesNonSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },

        chiffreAffaireDetails: {
          venteChambre: null,
          restauConsommation: null,
          locationSalle: null,
          autres: null,
        },
        // renseignement exploitation
        chargesSalariales: null,
        autresDepense: null,
        nombreArrives: null,
        nombreNuites: null,
        tauxOccuptationMensuelChambresParChambre: null,
        // tauxOccuptationMensuelChambresParLit: null,
        auteur: null,
      },
      forms_curr_month: [],
      currentMonthDays: 0,
      invalideAgrement: false,
      mauvaisEtablissement: false,
      invalideIfu: false,
      auteur: "Tarinus",

      controleLitFailed: false,
      controleHebergementFailed: false,
    };
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left") {
        return "layout-content-renderer-left";
      }
      if (rendererType === "sidebar-left-detached") {
        return "layout-content-renderer-left-detached";
      }
      return "layout-content-renderer-default";
    },
    get_sous_type() {
      const { currentUserId, etablissementDetails } =
        utilsService.currentUserUtils();
      const { sousTypeEtablissement } = etablissementDetails;
      return sousTypeEtablissement;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.annees = utilsService.getListYears();
        this.mois = utilsService.getListMonths();
        this.pays = utilsService.getListCountries();
        // console.log("val", val, this.formInput);
        const userId = localstorageService.getUserId();
        if (this.formInput) {
          this.isCheckingForLastForm = false;
          this.isFillingForm = true;
          // this.infos = { ...this.formInput, ...autofillableFields }
          this.infos = { ...this.formInput };
          this.initialsValues = this.customCloneDeep(this.infos);
          setTimeout(() => {
            this.isFillingForm = false;
          }, 1000);
        } else {
          const etablissementDetails = this.etablissementDetails || {};
          const autofillableFields = {};
          this.autofillableFields.map((e) => {
            let foundItem = etablissementDetails[e.name];
            if (!foundItem) foundItem = etablissementDetails[e.key];
            if (foundItem) {
              autofillableFields[e.name] = foundItem;
            }
            return e;
          });

          // autofillableFields.annee = new Date().getFullYear();
          // autofillableFields.mois = this.mois[new Date().getMonth()];
          setTimeout(() => {
            this.isCheckingForLastForm = false;
          }, 1000);
          this.infos = { ...this.infos, ...autofillableFields };
          this.initialsValues = this.customCloneDeep(this.infos);
        }
        // console.log('⭕️', this.removeinvalidMonth(this.mois))
        // this.mois = this.removeinvalidMonth(this.mois)

        // chargement des fiches stats
        // console.log('⭕️', this.etablissementDetails._id)
        // const selected_month = this.formattedDate(
        //   this.infos.annee,
        //   this.infos.mois
        // );
      },
    },

    "infos.mois": {
      handler(val, old) {
        let month = 0;
        this.mois.map((e, index) => {
          if (e == this.infos.mois) {
            month = index + 1;
          }
        });
        console.log("minth", month);
        const period = this.getFirstAndLastDaysForCurrMouth(
          this.infos.annee,
          month
        );
        // console.log('period::: ⭕️', period);
        const period_params = `${period[0]},${period[1]}`;
        this.currentMonthDays = this.calculateDifference(period[0], period[1]);
        console.log("⭕️", this.apiRoutePrefixe);
        this.fetchFormulaires({
          formType: "forms-clients-hotels",
          params: {
            options: { etablissement: this.etablissementDetails._id },
            filter: { type: ["createdAt"], text: [period_params] },
          },
        })
          .then((res) => {
            console.log("res::: ⭕️", res.data.resources);

            if (res.status == 200) {
              this.forms_curr_month = [];
              this.forms_curr_month = res.data.resources;
              console.log("this.forms_curr_month::: ", this.forms_curr_month);
              (this.infos.nombreArrives = this.forms_curr_month.length),
                this.forms_curr_month.map((e) => {
                  this.infos.nombreNuites =
                    this.infos.nombreNuites +
                    this.calculateDifference(e.dateArrivee, e.dateDepart);
                });

              console.log("this.infos::: ", this.infos);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
    "infos.departement": {
      handler(val, old) {
        if (this.isFillingForm) return;
        (this.infos.commune = ""), (this.communes = []);
        this.loadCommunes();
      },
    },
    "infos.commune": {
      handler(val, old) {
        if (this.isFillingForm) return;
        (this.infos.arrondissement = ""), (this.arrondissements = []);
        this.loadArrondissements();
      },
    },
    "infos.arrondissement": {
      handler(val, old) {
        if (this.isFillingForm) return;
        (this.infos.quartier = ""), (this.quartiers = []);
        this.loadNeighborhoods();
      },
    },

    "infos.capacitesAccueil.nombreChambresDisponibles": {
      handler(val, old) {
        if (val && val > this.infos.capacitesAccueil.nombreChambres) {
          this.controleHebergementFailed = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Le nombre de chambres ou unités d'hébergement disponibles doit être inférieur ou égal au nombre total de chambres ou unités d'hébergements ",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else {
          this.controleHebergementFailed = false;
        }
      },
    },
    "infos.capacitesAccueil.nombreChambres": {
      handler(val, old) {
        if (
          val &&
          val < this.infos.capacitesAccueil.nombreChambresDisponibles
        ) {
          this.controleHebergementFailed = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Le nombre de chambres ou unités d'hébergement disponibles doit être inférieur ou égal au nombre total de chambres ou unités d'hébergements ",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else {
          this.controleHebergementFailed = false;
        }
      },
    },

    "infos.capacitesAccueil.nombreLits": {
      handler(val, old) {
        console.log("val, old: ", val, old);
        if (val < this.infos.capacitesAccueil.nombreChambresDisponibles) {
          this.controleLitFailed = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Le nombre total de places - lits disponible doit être superieur ou égal au  nombre de chambres ou unités d'hébergement disponibles",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else {
          this.controleLitFailed = false;
        }
      },
    },
    "infos.capacitesAccueil.nombreLitsDisponible": {
      handler(val, old) {
        console.log("val, old: ", val, old);
        if (val > this.infos.capacitesAccueil.nombreLits) {
          this.controleLitFailed = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Le nombre total de places - lits disponible doit être inférieur ou égal au nombre total de places - lits",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else {
          this.controleLitFailed = false;
        }
      },
    },

    // 'infos.annee': {
    //   handler(val, old) {
    //     // console.log('val, old: ', val, old)
    //     let currentYear = new Date().getFullYear()
    //     this.infos.mois = ''
    //     // load current year
    //     if( val === currentYear){ // load short list
    //       this.mois = this.removeinvalidMonth(this.mois)
    //     }else{ // load long list
    //       this.mois = utilsService.getListMonths()
    //     }
    //   },
    // },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {
    this.getListTypesEtablissement();
    this.loadDepartments();
    this.loadCommunes();
    this.loadArrondissements();
  },
  methods: {
    ...mapActions("params", {
      fetchAnnees: "fetchAnnees",
    }),
    ...mapActions("formulaire", {
      getLastFormCreatedByUser: "getLastFormCreatedByUser",
      fetchFormulaires: "fetchFormulaires",
    }),
    ...mapActions("utils", {
      checkIFU: "checkIFU",
    }),
    ...mapActions("params", {
      action_loadDepartments: "loadDepartments",
      action_loadCommunes: "loadCommunes",
      action_loadArrondissements: "loadArrondissements",
      action_loadNeighborhoods: "loadNeighborhoods",
    }),
    ...mapActions("etablissements", {
      createEtablissement: "createEtablissement",
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    // -------------------------------------------------------------

    loadDepartments() {
      this.action_loadDepartments()
        .then((res) => {
          this.departements = res;
        })
        .catch((err) => {});
    },

    loadCommunes() {
      this.action_loadCommunes(this.infos.departement)
        .then((res) => {
          this.communes = res;
        })
        .catch((err) => {});
    },
    loadArrondissements() {
      this.action_loadArrondissements(this.infos.commune)
        .then((res) => {
          this.arrondissements = res;
        })
        .catch((err) => {});
    },
    loadNeighborhoods() {
      this.action_loadNeighborhoods(this.infos.arrondissement)
        .then((res) => {
          this.quartiers = res;
        })
        .catch((err) => {});
    },
    calculateTaux(nombreChambresDisponibles) {
      const nuiteesTheoriques =
        nombreChambresDisponibles * this.currentMonthDays;

      this.infos.tauxOccuptationMensuelChambresParChambre =
        this.normalizeNumericValue(
          (this.infos.nombreNuites / nuiteesTheoriques) * 100
        );
      console.log(this.infos.tauxOccuptationMensuelChambresParChambre);
    },
    getListTypesEtablissement() {
      this.action_getListTypesEtablissement()
        .then((res) => {
          // console.log("typeets", res);
          // this.typesEts = res.data.typeetablissements || [];
        })
        .catch((err) => {});
    },
    showModal() {
      this.$refs[this.tipsModalRef].show();
    },
    hideModal() {
      this.$refs[this.tipsModalRef].hide();
    },
    fillFormWithLastRecord() {
      this.isFillingForm = true;
      this.infos = this.lastRecordedForm;
      setTimeout(() => {
        this.isFillingForm = false;
      }, 1000);
      this.hideModal();
      // console.log(this.infos);
    },
    sendToPreview() {
      const { currentUserId, etablissementDetails } =
        utilsService.currentUserUtils();
      const { sousTypeEtablissement } = etablissementDetails;
      this.$refs.formRules.validate().then(async (success) => {
        if (success) {
          const canSend = true;
          // clearing data
          const form = {
            ...this.infos,
            // typeEtablissement: sousTypeEtablissement,
            typeEtablissement: this.infos.typeEtablissement,
            annee: `${this.infos.annee}`,
            ...(this.infos.departement.name && {
              departement: this.infos.departement.name,
            }),
            ...(this.infos.commune.name && {
              commune: this.infos.commune.name,
            }),
            ...(this.infos.arrondissement.name && {
              arrondissement: this.infos.arrondissement.name,
            }),
            ...(this.infos.quartier.name && {
              quartier: this.infos.quartier.name,
            }),
            ...(this.infos.nationalitePromoteur.name && {
              nationalitePromoteur: this.infos.nationalitePromoteur.name,
            }),
            auteur: currentUserId || "",
          };

          // form.auteur = currentUserId

          if (form.telephone2 == "") delete form.telephone2;
          if (form.telephone3 == "") delete form.telephone3;
          if (form.qrcode) delete form.qrcode;
          if (form._id) delete form._id;

          console.log(
            "------------------------- finale data form: ===> ",
            form
          );
          // check validity of agrement
          // if (form.agrementMinistere == "non") {
          //   delete form.referenceAgrement;
          // } else {
          //   (this.mauvaisEtablissement = false),
          //     (this.invalideAgrement = false);
          //   this.agrements.forEach((agrement, index) => {
          //     if (agrement.reference == form.referenceAgrement) {
          //       if (agrement.etablissement != form.nomEtablissement) {
          //         this.mauvaisEtablissement = true;
          //         return;
          //       } else {
          //         this.invalideAgrement = false;
          //         return;
          //       }
          //     } else {
          //       this.invalideAgrement = true;
          //     }
          //   });
          //   if (this.invalideAgrement || this.mauvaisEtablissement) {
          //     canSend = false;
          //     this.$toast({
          //       component: ToastificationContent,
          //       props: {
          //         title: this.mauvaisEtablissement
          //           ? "Mauvais établissement associé à l'agrément"
          //           : "Agrément invalide",
          //         icon: "AlertTriangleIcon",
          //         variant: "danger",
          //       },
          //     });
          //   }
          // }

          // check validity of IFU
          // if (form.numIFU != "") {
          //   this.invalideIfu = false;
          //   const res = await this.checkIFU({
          //     numeroIfu: form.numIFU,
          //   });
          // console.log(res)
          //   if (!res.data.success) {
          //     canSend = false;
          //     this.$toast({
          //       component: ToastificationContent,
          //       props: {
          //         title: "Numéro IFU Invalide",
          //         icon: "AlertTriangleIcon",
          //         variant: "danger",
          //       },
          //     });
          //     this.invalideIfu = true;
          //   }
          // }

          if (this.controleLitFailed) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  "Le nombre total de places lits disponible doit être inférieur ou égal au nombre total de places lits en cours",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          if (this.controleHebergementFailed) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  "Le nombre total unité d’hébergement doit être inférieur ou égal au nombre total d’hébergement en cours",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }

          if (canSend) {
            this.$emit("onFormSucess", {
              routerParams: { preview: true, data: form },
            });
          }
        } else {
          const errorFirst = this.$el.querySelector(".is-invalid");
          // console.log(errorFirst);
          if (errorFirst) {
            // const errorFirstInput = errorFirst.querySelector('label')
            errorFirst.scrollIntoView({ behavior: "instant" });
            errorFirst.focus();
          }
        }
      });
    },
    onFormHeaderSuccess($event) {
      console.log("$event :>> ", $event);
      this.infos = { ...this.infos, annee: $event.annee, mois: $event.mois };
    },
    // loadPays() {
    //   this.pays = [{ name: "Bénin" }, { name: "Togo" }, { name: "Niger" }];
    // },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/themes/bordered-layout.scss";

.ml-custom {
  margin-left: 2rem;
}

.w-88 {
  width: 72%;
}
</style>
