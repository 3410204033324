<template>
  <div>
    <b-card class="mb-2">

      <h2>Ajouter un agent</h2>

      isCheckingAgentCount {{ isCheckingAgentCount }}
      canAddNewAgent {{ canAddNewAgent }}

    </b-card>
    <b-card class="mb-0">
      <!-- form -->
      <div v-if="isCheckingAgentCount">
        <p> Vérification en cours... </p>
        <p>
          <b-spinner
            style="width: 3rem; height: 3rem;"
            class="mx-auto d-block"
          />
        </p>
      </div>
      <div v-if="!isCheckingAgentCount && !canAddNewAgent">
        <b-alert
          class="mt-2"
          variant="warning"
          show
        >
          <h4 class="alert-heading">
            Note !
          </h4>
          <div class="alert-body">
            <span>Un établissement ne peut créer que cinq(05) agents</span>
          </div>
        </b-alert>
      </div>

      <b-form
        v-if="!isCheckingAgentCount && canAddNewAgent"
        class="auth-register-form mt-2 row"
        @submit.prevent="submitForm"
      >
        <!-- nom -->
        <b-form-group
          label="Nom"
          label-for="nom"
          class="col-md-3"
        >
          <b-form-input
            id="nom"
            v-model="form.nom"
            :state="$v.form.nom.$error ? false : null"
            name="nom"
          />
          <div v-if="$v.form.nom.$error">
            <small
              v-if="$v.form.nom.required.$invalid"
              class="text-danger"
            >Ce champ est requis</small>
          </div>
        </b-form-group>
        <!-- prenom -->
        <b-form-group
          label="Prénom"
          label-for="prenom"
          class="col-md-5"
        >
          <b-form-input
            id="prenom"
            v-model="form.prenom"
            :state="$v.form.prenom.$error ? false : null"
            name="prenom"
          />
          <div v-if="$v.form.prenom.$error">
            <small
              v-if="$v.form.prenom.required.$invalid"
              class="text-danger"
            >Ce champ est requis</small>
          </div>
        </b-form-group>
        <!-- email -->
        <b-form-group
          label="Email"
          label-for="email"
          class="col-md-4"
        >
          <b-form-input
            id="email"
            v-model="form.email"
            :state="$v.form.email.$error ? false : null"
            name="email"
          />
          <div v-if="$v.form.email.$error">
            <small
              v-if="$v.form.email.required.$invalid"
              class="text-danger"
            >Ce champ est requis</small>
            <small
              v-if="$v.form.email.email.$invalid"
              class="text-danger"
            >Adresse email invalid</small>
          </div>
        </b-form-group>
        <!-- password -->
        <b-form-group
          label-for="register-password"
          label="Mot de passe"
          class="col-md-6 password-field"
        >
          <b-input-group
            class="input-group-merge"
            :class="$v.form.password.$error ? 'is-invalid' : null"
          >
            <b-form-input
              id="register-password"
              v-model="form.password"
              class="form-control-merge"
              :type="passwordFieldType"
              :state="$v.form.password.$error ? false : null"
              name="register-password"
              placeholder="············"
              @focus="passwordFieldIsFocused = true"
              @blur="passwordFieldIsFocused = false; $v.form.password.$touch"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
                class="cursor-pointer"
                @click="togglePasswordInputType"
              />
            </b-input-group-append>
          </b-input-group>
          <div
            v-if="passwordFieldIsFocused || $v.form.password.$error"
            class="invalid-feedback password-rules"
          >
            <small
              v-if="
                passwordFieldIsFocused || $v.form.password.isValid.$invalid
              "
            >
              <span class="label"> Le mot de passe doit contenir :</span>
              <ul class="list-style-none password-validators-errors">
                <li
                  :class="{
                    valid: passwordValidators(form.password)
                      .atLeastOneCapLetter,
                  }"
                >
                  <div class="icons">
                    <i
                      v-if="
                        !passwordValidators(form.password)
                          .atLeastOneCapLetter
                      "
                      class="las la-times-circle"
                    />
                    <i
                      v-if="
                        passwordValidators(form.password)
                          .atLeastOneCapLetter
                      "
                      class="las la-check-circle"
                    />
                  </div>
                  au moins une lettre majuscule
                </li>
                <li
                  :class="{
                    valid: passwordValidators(form.password)
                      .atLeastOneMinusLetter,
                  }"
                >
                  <div class="icons">
                    <i
                      v-if="
                        !passwordValidators(form.password)
                          .atLeastOneMinusLetter
                      "
                      class="las la-times-circle"
                    />
                    <i
                      v-if="
                        passwordValidators(form.password)
                          .atLeastOneMinusLetter
                      "
                      class="las la-check-circle"
                    />
                  </div>
                  au moins une lettre minuscule
                </li>
                <li
                  :class="{
                    valid: passwordValidators(form.password)
                      .atLeastOneDigits,
                  }"
                >
                  <div class="icons">
                    <i
                      v-if="
                        !passwordValidators(form.password).atLeastOneDigits
                      "
                      class="las la-times-circle"
                    />
                    <i
                      v-if="
                        passwordValidators(form.password).atLeastOneDigits
                      "
                      class="las la-check-circle"
                    />
                  </div>
                  au moins un chiffre
                </li>
                <li
                  :class="{
                    valid: passwordValidators(form.password)
                      .atLeastOneSpecialChar,
                  }"
                >
                  <div class="icons">
                    <i
                      v-if="
                        !passwordValidators(form.password)
                          .atLeastOneSpecialChar
                      "
                      class="las la-times-circle"
                    />
                    <i
                      v-if="
                        passwordValidators(form.password)
                          .atLeastOneSpecialChar
                      "
                      class="las la-check-circle"
                    />
                  </div>
                  au moins un caractère special (@#$%^&+=)
                </li>
                <li
                  :class="{
                    valid: passwordValidators(form.password).minimumLength,
                  }"
                >
                  <div class="icons">
                    <i
                      v-if="
                        !passwordValidators(form.password).minimumLength
                      "
                      class="las la-times-circle"
                    />
                    <i
                      v-if="passwordValidators(form.password).minimumLength"
                      class="las la-check-circle"
                    />
                  </div>
                  au moins 8 caractères
                </li>
              </ul>
            </small>
          </div>
        </b-form-group>

        <!-- confirm password -->
        <b-form-group
          class="col-md-6"
          label-for="register-password"
          label="Confirmer le mot de passe"
        >
          <b-input-group
            class="input-group-merge"
            :class="$v.form.confirmPassword.$error ? 'is-invalid' : null"
          >
            <b-form-input
              id="register-password"
              v-model="form.confirmPassword"
              class="form-control-merge"
              :type="passwordFieldType"
              :state="$v.form.confirmPassword.$error ? false : null"
              name="register-password"
              placeholder="············"
              @blur="$v.form.confirmPassword.$touch"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
                class="cursor-pointer"
                @click="togglePasswordInputType"
              />
            </b-input-group-append>
          </b-input-group>
          <div v-if="$v.form.confirmPassword.$error">
            <small
              v-if="$v.form.confirmPassword.isValid.$invalid"
              class="text-danger"
            >Les mots de passe ne sont pas identiques</small>
          </div>
        </b-form-group>
        <!-- can validate stats mensuel fiches -->
        <b-form-group
          label="Autorisation Fiche statistiques mensuelles"
          label-for="validateStatsFiches"
          class="col-md-12"
        >
          <b-form-checkbox
            v-model="form.canValidateFicheStatMensuel"
            value="true"
            class="custom-control-primary"
            name="validateStatsFiches"
          >
            Peut valider les fiches statistiques mensuelles
          </b-form-checkbox>
        </b-form-group>

        <div
          v-if="false"
          class="mb-1"
        >
          <!-- Styled -->
          <RecaptchaWidget @onRecaptchaChange="onRecaptchaChange" />
          <small
            v-if="$v.recaptchaResponse.isValid.$invalid"
            class="text-danger"
          >Veuillez résoudre le recaptcha pour continuer</small>
        </div>

        <!-- submit button -->
        <b-button
          variant="primary"
          block
          type="submit"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          Inscrire
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
import passwordValidators from '@/helpers/password-validators'
// import localstorageService from '@/services/localstorage/localstorage.service'
import utilsService from '@/services/utils/utils.service'

// store module and vuex utilities
import etablissementsStroreModule from '@/store/etablissements'
import paramsStoreModule from '@/store/params'
import usersStoreModule from '@/store/users'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'params', module: paramsStoreModule },
      { path: 'users', module: usersStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return {
      passwordValidators,
      requiredStoreModules,
      $v: useVuelidate({ $lazy: true }),
    }
  },
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    AppLogo,
    vSelect,
    RecaptchaWidget,
    BSpinner,
    BAlert,
  },
  data() {
    return {
      form: {
        nom: '',
        prenom: '',
        email: '',
        etablissement: null,
        canValidateFicheStatMensuel: false,
        password: '',
        confirmPassword: '',
      },
      // currentUserId: localstorageService.getUserId(),

      recaptchaResponse: null,
      passwordFieldType: 'password',
      passwordFieldIsFocused: false,
      isLoading: false,
      isCheckingAgentCount: false,
      canAddNewAgent: false,
      listEtablissements: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.checkAgentCount()
      },
    },
    passwordFieldIsFocused: {
      handler(val, old) {
        this.$v.form.password.$validate()
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },
  validations() {
    return {
      form: {
        nom: { required },
        prenom: { required },
        email: { required, email },
        password: {
          required,
          isValid(value) {
            return this.passwordValidators(value).isValid
          },
        },
        confirmPassword: {
          isValid: val => val && val === this.form.password,
        },
        // canValidateFicheStatMensuel: { required },
        // etablissement: { required },
      },

      // recaptchaResponse: { isValid: val => val && val.type === 'success' },
      isLoading: false,
    }
  },
  mounted() {},
  methods: {
    ...mapActions('etablissements', {
      action_createAgent: 'createAgent',
      action_findEtablissement: 'findEtablissement',
    }),
    ...mapActions('users', {
      action_fetchUsers: 'fetchUsers',
      action_fetchAgentsEtab: 'fetchAgentsEtab',
    }),
    ...mapActions('params', { action_fetchTypesRoles: 'fetchTypesRoles' }),
    ...mapActions('imagePuzzleSliderCaptcha', { showSliderCaptcha: 'show' }),
    // -------------------------------------------------------------------------
    togglePasswordInputType() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    async  checkAgentCount() {
      this.isCheckingAgentCount = true
      const { etablissementId } = utilsService.currentUserUtils()

      const params = {
        options: {
          // role: userRoleId,
          // isArchive: false,
          etablissement: etablissementId,
        },
      }
      this.action_fetchAgentsEtab(params)
        .then(response => {
          this.isCheckingAgentCount = false
          const agentCount = response.data.total || 0
          this.canAddNewAgent = agentCount === 0 || agentCount < 5
        })
        .catch(err => {
          this.isCheckingAgentCount = false
          console.log('err: ', err)
        })
    },
    onRecaptchaChange(recaptchaEvent) {
      if (recaptchaEvent && recaptchaEvent.type === 'success') {
        this.recaptchaResponse = recaptchaEvent
      } else {
        this.recaptchaResponse = null
      }
    },
    async submitForm() {
      if (this.isLoading) return
      this.isLoading = true
      const isFormCorrect = await this.$v.$validate()
      if (!isFormCorrect) return

      // const sliderCaptchaResponse = await this.showSliderCaptcha()
      // const {isSolved} = sliderCaptchaResponse || {}
      // console.log('sliderCaptchaResponse',isSolved);
      // if(!isSolved){
      //   this.isLoading = false
      //   return
      // }

      const {
        isAdminEtablissement, isAgentEtablissement, currentUserId, etablissementId,
      } = utilsService.currentUserUtils()
      if (!isAdminEtablissement) {
        this.isLoading = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: "Vous n'êtes pas autorisé à faire cette action",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('submit')
      if (!token) {
        this.isLoading = false
        return
      }

      const data = {
        firstname: this.form.nom,
        lastname: this.form.prenom,
        email: this.form.email,
        password: this.form.password,
        etablissementId,
        token,
      }
      if (this.form.canValidateFicheStatMensuel) data.canValidateFicheStatMensuel = true

      delete data.confirmPassword
      // console.log('data::: 🔴', data)
      // return

      this.action_createAgent(data)
        .then(result => {
          this.isLoading = false
          // return
          this.$router.push({ name: 'etablissements-agents-list' })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opération réussie',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
