var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-card',{staticClass:"pl-5 pr-5"},[_c('b-card-header',[_c('b-row',{staticClass:"w-100",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-popover",rawName:"v-b-popover.hover.focus",value:(
                    '⚪️ Cette fiche concerne l\'enregistrement des clients dans les hôtels et établissements d\'hébergement assimilés  ⚪️ La fiche est remplie par les promoteurs des établissements et leurs agents.'
                  ),expression:"\n                    '⚪️ Cette fiche concerne l\\'enregistrement des clients dans les hôtels et établissements d\\'hébergement assimilés  ⚪️ La fiche est remplie par les promoteurs des établissements et leurs agents.'\n                  ",modifiers:{"hover":true,"focus":true}}],staticClass:"btn-icon rounded-circle",attrs:{"size":"lg","variant":"outline-primary","title":"Information"}},[_c('feather-icon',{attrs:{"icon":"BellIcon"}})],1)],1)])]),_c('b-col',{attrs:{"md":"2"}},[_c('b-card-img',{staticClass:"rounded-0 my-2",attrs:{"src":require('@/assets/images/illustration/hébergement.png'),"alt":"Image"}})],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"md":"10"}},[_c('div',[_c('br'),_c('h4',{staticClass:"m-auto text-center uppercase"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"NPI","label-cols-sm":"3","label-cols-lg":"1"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({ length: 10 }),expression:"{ length: 10 }"}],attrs:{"placeholder":"Numéro personnel d'identification"},model:{value:(_vm.infosEnregistrementClients.npi),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "npi", $$v)},expression:"infosEnregistrementClients.npi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-2 col-form-label col-form-label-md",attrs:{"for":"dateArrivee"}},[_vm._v(" Date d' "),_c('br'),_vm._v("arrivée"),_c('RequiredStarComponent')],1),_c('validation-provider',{attrs:{"rules":"required|beforeOrToday"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"lang":"fr-CA","type":"date","placeholder":"Date d'arrivée"},model:{value:(_vm.infosEnregistrementClients.dateArrivee),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "dateArrivee", $$v)},expression:"infosEnregistrementClients.dateArrivee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-2 col-form-label col-form-label-md",attrs:{"for":"heureArrivee"}},[_vm._v(" Heure"),_c('RequiredStarComponent')],1),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Heure","config":{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                  }},model:{value:(_vm.infosEnregistrementClients.heureArrivee),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "heureArrivee", $$v)},expression:"infosEnregistrementClients.heureArrivee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-2 col-form-label col-form-label-md",attrs:{"for":"numeroChambre"}},[_vm._v(" Chambre"),_c('RequiredStarComponent')],1),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"K12"},model:{value:(_vm.infosEnregistrementClients.numeroChambre),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "numeroChambre", $$v)},expression:"infosEnregistrementClients.numeroChambre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"float-left mr-5 mx-auto col-form-label col-form-label-md",attrs:{"for":"nationalité"}},[_vm._v("Date de"),_c('br'),_vm._v(" départ"),_c('RequiredStarComponent',{staticClass:"mr-2"})],1),_c('validation-provider',{staticClass:"float-left ml-2",attrs:{"rules":{
                required: _vm.required,
                date_between: [
                  _vm.infosEnregistrementClients.dateArrivee,
                  null,
                  false ],
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"lang":"fr-FR","type":"date","placeholder":"Date de départ"},model:{value:(_vm.infosEnregistrementClients.dateDepart),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "dateDepart", $$v)},expression:"infosEnregistrementClients.dateDepart"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:" col-form-label col-form-label-md",attrs:{"for":"nationalité"}},[_vm._v("Nom:"),_c('RequiredStarComponent',{staticClass:"mr-3"})],1),_c('validation-provider',{staticClass:"mr-3 w-75",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nom"},model:{value:(_vm.infosEnregistrementClients.lastname),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "lastname", $$v)},expression:"infosEnregistrementClients.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:" col-form-label col-form-label-md",attrs:{"for":"nationalité"}},[_vm._v("Prénoms :"),_c('RequiredStarComponent',{staticClass:"mr-1"})],1),_c('validation-provider',{staticClass:"mr-1 w-75",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Prénoms"},model:{value:(_vm.infosEnregistrementClients.firstname),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "firstname", $$v)},expression:"infosEnregistrementClients.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:" col-form-label col-form-label-md",attrs:{"for":"nationalité"}},[_vm._v("Sexe:"),_c('RequiredStarComponent')],1),_c('validation-provider',{staticClass:"ml-5",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing mb-1 ml-1",attrs:{"options":_vm.sexe,"value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.infosEnregistrementClients.sexe),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "sexe", $$v)},expression:"infosEnregistrementClients.sexe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"12"}},[(_vm.infosEnregistrementClients.sexe === 'Féminin')?_c('b-form-group',{attrs:{"rules":"alpha"}},[_c('label',{staticClass:"float-left col-form-label col-form-label-md",attrs:{"for":"nationalité"}},[_vm._v("Nom de jeune fille : ")]),_c('b-form-input',{staticClass:"float-left ml-2 w-75",attrs:{"placeholder":"Nom de la jeune fille"},model:{value:(_vm.infosEnregistrementClients.maidenname),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "maidenname", $$v)},expression:"infosEnregistrementClients.maidenname"}})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}}),_c('b-col',{attrs:{"md":"6"}})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:" col-form-label col-form-label-md",attrs:{"for":"nationalité"}},[_vm._v("Date de"),_c('br'),_vm._v(" naissance :"),_c('RequiredStarComponent',{staticClass:" "})],1),_c('validation-provider',{staticClass:" ml-1 w-75",attrs:{"rules":"required|beforeOrToday|valideBirthdate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"placeholder":"Date de naissance","lang":"fr-FR","type":"date"},model:{value:(_vm.infosEnregistrementClients.dateNaissance),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "dateNaissance", $$v)},expression:"infosEnregistrementClients.dateNaissance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:" col-form-label col-form-label-md",attrs:{"for":"lieu_naissance"}},[_vm._v("Lieu de"),_c('br'),_vm._v(" naissance :"),_c('RequiredStarComponent',{})],1),_c('validation-provider',{staticClass:"ml-1 w-75",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"lieu_naissance"},model:{value:(_vm.infosEnregistrementClients.lieuNaissance),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "lieuNaissance", $$v)},expression:"infosEnregistrementClients.lieuNaissance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:" col-form-label col-form-label-md",attrs:{"for":"Lieu de naissance"}},[_vm._v("Nationalité :"),_c('RequiredStarComponent',{staticClass:" "})],1),_c('validation-provider',{staticClass:" ml-1 w-75",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SelectCountriesList',{attrs:{"input-value":_vm.infosEnregistrementClients.nationalite,"placeholder":'Sélectionner le pays de votre nationalité'},model:{value:(_vm.infosEnregistrementClients.nationalite),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "nationalite", $$v)},expression:"infosEnregistrementClients.nationalite"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-form-group',[_c('label',{attrs:{"for":""}},[_c('strong',[_vm._v("Lieu de résidence habituelle :")])]),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Adresse"}},[_c('b-form-input',{attrs:{"placeholder":"L'adresse du lieu de résidence"},model:{value:(_vm.infosEnregistrementClients.lieuResidence.adresse),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.lieuResidence, "adresse", $$v)},expression:"infosEnregistrementClients.lieuResidence.adresse"}})],1),_c('b-row',[_c('b-col',{staticClass:"md-6"},[_c('b-form-group',{attrs:{"label-cols-md":"4","label":"Code Postal"}},[_c('b-form-input',{attrs:{"placeholder":"Le code postal du lieu de résidence"},model:{value:(_vm.infosEnregistrementClients.lieuResidence.bp),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.lieuResidence, "bp", $$v)},expression:"infosEnregistrementClients.lieuResidence.bp"}})],1)],1),_c('b-col',{staticClass:"md-6"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center "},[_c('label',{staticClass:"float-left col-form-label col-form-label-md   ",attrs:{"for":"Lieu de naissance"}},[_vm._v("Pays:"),_c('RequiredStarComponent',{})],1),_c('validation-provider',{staticClass:" w-55 ml-custom-largea",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SelectCountriesList',{attrs:{"input-value":_vm.infosEnregistrementClients.lieuResidence.pays,"placeholder":"Pays du lieu de résidence"},model:{value:(_vm.infosEnregistrementClients.lieuResidence.pays),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.lieuResidence, "pays", $$v)},expression:"infosEnregistrementClients.lieuResidence.pays"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1)],1),_c('b-row',[_c('b-col',{staticClass:"md-6"},[_c('b-form-group',{attrs:{"label-cols-md":"4","label":"Département"}},[(
                  _vm.infosEnregistrementClients.lieuResidence.pays !== 'Bénin'
                )?_c('b-form-input',{attrs:{"placeholder":"Département du lieu de résidence"},model:{value:(_vm.infosEnregistrementClients.lieuResidence.departement),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.lieuResidence, "departement", $$v)},expression:"infosEnregistrementClients.lieuResidence.departement"}}):_c('span',[_c('v-select',{attrs:{"type":"text","options":_vm.departement,"label":"name","placeholder":"Département du lieu de résidence"},on:{"input":function($event){return _vm.getVilleCommune(
                      _vm.infosEnregistrementClients.lieuResidence.departement
                    )}},model:{value:(
                    _vm.infosEnregistrementClients.lieuResidence.departement
                  ),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.lieuResidence, "departement", $$v)},expression:"\n                    infosEnregistrementClients.lieuResidence.departement\n                  "}})],1)],1)],1),_c('b-col',{staticClass:"md-6"},[_c('b-form-group',{attrs:{"label-cols-md":"4","label":"Ville/Commune :"}},[(
                  _vm.infosEnregistrementClients.lieuResidence.pays !== 'Bénin'
                )?_c('b-form-input',{attrs:{"placeholder":"Ville du lieu de résidence"},model:{value:(
                  _vm.infosEnregistrementClients.lieuResidence.villeCommune
                ),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.lieuResidence, "villeCommune", $$v)},expression:"\n                  infosEnregistrementClients.lieuResidence.villeCommune\n                "}}):_c('span',[_c('v-select',{attrs:{"reduce":function (option) { return option.name; },"type":"text","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.ville_commune,"label":"name","placeholder":" Ville ou Commune du lieu de résidence"},model:{value:(
                    _vm.infosEnregistrementClients.lieuResidence.villeCommune
                  ),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.lieuResidence, "villeCommune", $$v)},expression:"\n                    infosEnregistrementClients.lieuResidence.villeCommune\n                  "}})],1)],1)],1)],1)],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Téléphone"}},[_c('validation-provider',{staticClass:"   ",attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"phonenumber-field",rawName:"v-phonenumber-field"}],attrs:{"placeholder":"Téléphone"},model:{value:(_vm.infosEnregistrementClients.telephone),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "telephone", $$v)},expression:"infosEnregistrementClients.telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Adresse email"}},[_c('validation-provider',{attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Adresse email"},model:{value:(_vm.infosEnregistrementClients.email),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "email", $$v)},expression:"infosEnregistrementClients.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{staticClass:"col-form-label col-form-label-md mr-5",attrs:{"for":"Lieu de naissance"}},[_vm._v("Catégorie socio professionnelle :"),_c('RequiredStarComponent',{staticClass:" "})],1),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"ml-custom-large",attrs:{"options":_vm.categories,"stacked":"","value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.infosEnregistrementClients.categorieSocioPro),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "categorieSocioPro", $$v)},expression:"infosEnregistrementClients.categorieSocioPro"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.infosEnregistrementClients.categorieSocioPro === 'true')?_c('b-form-group',{staticClass:"mt-1"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Autres catégories socio professionnelles"},model:{value:(_vm.infosEnregistrementClients.categorieSocioProAutres),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "categorieSocioProAutres", $$v)},expression:"infosEnregistrementClients.categorieSocioProAutres"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1269572454)})],1):_vm._e()],1),_c('b-form-group',[_c('label',{staticClass:"col-form-label col-form-label-md mr-5",attrs:{"for":"Lieu de naissance"}},[_vm._v("Mode principal de transport à l'intérieur du Bénin :"),_c('RequiredStarComponent',{staticClass:" "})],1),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing mb-1 ml-custom-large",attrs:{"options":_vm.modePrincipalTransport,"value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.infosEnregistrementClients.modeTransportInterieurPays),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "modeTransportInterieurPays", $$v)},expression:"infosEnregistrementClients.modeTransportInterieurPays"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{staticClass:"col-form-label col-form-label-md mr-5",attrs:{"for":"Lieu de naissance"}},[_vm._v("Motif principal du voyage :"),_c('RequiredStarComponent',{staticClass:" "})],1),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"ml-custom-large",attrs:{"options":_vm.motifPrincipalVoyage,"stacked":"","value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.infosEnregistrementClients.motifPrincipalVoyage),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "motifPrincipalVoyage", $$v)},expression:"infosEnregistrementClients.motifPrincipalVoyage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.infosEnregistrementClients.motifPrincipalVoyage === 'true')?_c('b-form-group',{staticClass:"mt-1"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Autres motifs principaux de voyage"},model:{value:(_vm.infosEnregistrementClients.motifPrincipalVoyageAutres),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "motifPrincipalVoyageAutres", $$v)},expression:"infosEnregistrementClients.motifPrincipalVoyageAutres"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1626467004)})],1):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Allez-vous recevoir une rémunération d'une entité du pays/Lieu visité durant votre séjour ?"}},[_c('RequiredStarComponent',{staticClass:" "}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing mb-1 ml-1",attrs:{"options":_vm.question,"value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.infosEnregistrementClients.possibiliteRemuneration),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "possibiliteRemuneration", $$v)},expression:"infosEnregistrementClients.possibiliteRemuneration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{staticClass:"col-form-label col-form-label-md mr-5",attrs:{"for":"Lieu de naissance"}},[_vm._v("Piece d'identité :"),_c('RequiredStarComponent',{staticClass:" "})],1),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing mb-1 ml-1",attrs:{"options":_vm.pieceIdentite,"value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.infosEnregistrementClients.pieceIdentite.type),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.pieceIdentite, "type", $$v)},expression:"infosEnregistrementClients.pieceIdentite.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:" col-form-label col-form-label-md ",attrs:{"for":"N° de la pièce"}},[_vm._v("N° de la pièce"),_c('RequiredStarComponent',{staticClass:" "})],1),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"w-50",attrs:{"type":"text","placeholder":"Numéro de la pièce"},model:{value:(_vm.infosEnregistrementClients.pieceIdentite.numeroPiece),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.pieceIdentite, "numeroPiece", $$v)},expression:"infosEnregistrementClients.pieceIdentite.numeroPiece"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:" col-form-label col-form-label-md mr-5",attrs:{"for":"Délivrée le"}},[_vm._v("Délivrée le"),_c('RequiredStarComponent',{staticClass:" "})],1),_c('validation-provider',{attrs:{"rules":"required|beforeOrToday"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"placeholder":"Délivrée le","lang":"fr-FR","type":"date"},model:{value:(_vm.infosEnregistrementClients.pieceIdentite.delivreLe),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.pieceIdentite, "delivreLe", $$v)},expression:"infosEnregistrementClients.pieceIdentite.delivreLe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:" col-form-label col-form-label-md ",attrs:{"for":"Délivrée le"}},[_vm._v("à"),_c('RequiredStarComponent',{staticClass:" "})],1),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:" w-50",attrs:{"placeholder":"à"},model:{value:(_vm.infosEnregistrementClients.pieceIdentite.a),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.pieceIdentite, "a", $$v)},expression:"infosEnregistrementClients.pieceIdentite.a"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1)],1)])],1),_c('div',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"col-xl-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){return _vm.sendinfosEnregistrementClients()}}},[_vm._v(" Aperçu avant enregistrement ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }