<template>
  <div>
    <b-card class="mb-1">
      <div class="d-flex flex-wrap justify-content-between">
        <h2>Détails de l'utilisateur</h2>

        <div
          v-if="!isFetchingUserDetails && itemDetails"
          class="d-flex flex-wrap"
        >
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-center
              class="mr-2"
              variant="primary"
              @click="sendRecupPasswordMail()"
            >
              <feather-icon icon="SendIcon" />
              Envoyer mail de récupération de MDP
              <b-spinner v-if="isLoadingSendMailResetPassword" small />
              <!-- <span class="text-nowrap" /> -->
            </b-button>
          </div>
          <!-- {{ itemDetails.isConfirmEmail }} -->
          <div class="res" v-if="!itemDetails.isConfirmEmail">
            <!-- @click="sendRecupPasswordMail()" -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-center
              class="mr-2"
              variant="primary"
              @click="validAccount()"
            >
              <!-- <feather-icon icon="SendIcon" /> -->
              <i class="las la-check"></i>
              Valider l'email
              <b-spinner v-if="isLoadingConfirmAccount" small />
              <!-- <span class="text-nowrap" /> -->
            </b-button>
          </div>

          <div class="res">
            <!-- {{itemDetails._id}} -->
            <ButtonArchiverUtilisateur
              v-if="itemDetails && isSuperAdmin"
              :utilisateur-id="itemDetails._id"
              redirect-route="users-list"
              class=""
            />
          </div>
        </div>
      </div>
    </b-card>
    <h1 v-if="!isFetchingUserDetails && !itemDetails">
      Aucun utilisateur ne correspond à cet enregistrement
    </h1>
    <content-loader
      v-if="isFetchingUserDetails"
      view-box="0 0 400 460"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <circle cx="29" cy="30" r="17" />
      <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
      <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
      <rect x="-7" y="60" rx="2" ry="2" width="408" height="132" />
    </content-loader>
    <b-card v-if="itemDetails" class="mb-0">
      <!-- infos détails -->
      <b-row>
        <b-col sm="12">
          <div class="row">
            <div class="col-md-6">
              <p><strong>Nom : </strong>{{ itemDetails.lastname }}</p>
              <p><strong>Email : </strong>{{ itemDetails.email }}</p>
              <p><strong>Téléphone : </strong>{{ itemDetails.telephone }}</p>
              <p v-if="itemDetails.etablissement">
                <strong>Établissement Associé : </strong
                >{{ itemDetails.etablissement.nomEtablissement }}
              </p>
              <p>
                <strong>Département : </strong
                >{{
                  itemDetails.departement
                    ? itemDetails.departement
                    : itemDetails.associated_department
                }}
              </p>
              <p v-if="itemDetails.role">
                <strong>Rôle : </strong>{{ itemDetails.role.nom }}
              </p>
            </div>
            <div class="col-md-6">
              <p><strong>Prénom : </strong>{{ itemDetails.firstname }}</p>
              <p><strong>Adresse : </strong>{{ itemDetails.adresse }}</p>
              <p><strong>Commune : </strong>{{ itemDetails.commune }}</p>
              <p>
                <strong>Poste (pour agent MTCA) : </strong
                >{{ itemDetails.poste }}
              </p>
              <p v-if="itemDetails.commissariatAssociated">
                <strong>Commissariat associé (pour agent de police): </strong
                >{{ itemDetails.commissariatAssociated.nom }}
              </p>
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- infos agents  -->
      <!-- <ListAgentPoliceComponent :commissariat-id="itemDetails._id" /> -->
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BModal,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BBadge,
  BTable,
  VBTooltip,
  VBModal,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useVuelidate from "@vuelidate/core";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import AppLogo from "@/components/AppLogo.vue";
import RecaptchaWidget from "@/components/RecaptchaWidget.vue";
import { ContentLoader } from "vue-content-loader";
// import ButtonArchiver from '@/views/apps/utilisateurs/etablissements/agents/components/ButtonArchiver.vue'
import Ripple from "vue-ripple-directive";

// store module and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import usersStoreModule from "@/store/users";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";
import ButtonArchiverUtilisateur from "@/views/apps/utilisateurs/administration/component/ButtonArchiverUtilisateur.vue";
import localstorageService from "@/services/localstorage/localstorage.service";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "users", module: usersStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules, $v: useVuelidate({ $lazy: true }) };
  },
  components: {
    BCard,
    BModal,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    BBadge,
    BTable,

    // validations
    AppLogo,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    RecaptchaWidget,
    BSpinner,
    BRow,
    BCol,
    ContentLoader,
    // ListAgentPoliceComponent,
    ButtonArchiverUtilisateur,
  },

  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      id: null,
      itemDetails: null,
      forms: [],
      isFetchingUserDetails: false,
      isLoadingTableData: false,
      loadingAddAgent: false,
      tableColumns: [
        { key: "_id", label: "#", sortable: true },
        { key: "reference", label: "Référence", sortable: true },
        { key: "createdAt", label: "Date", sortable: true },
        { key: "nomEtablissement", label: "Etablissement", sortable: true },
        { key: "statut", label: "Statut", sortable: true },
        { key: "actions" },
      ],

      isLoadingSendMailResetPassword: false,
      isLoadingConfirmAccount: false,
      isSuperAdmin: false,
    };
  },
  computed: {
    etablissementDetails() {
      return this.itemDetails && this.itemDetails.etablissement;
    },
    userId() {
      return this.itemDetails && this.itemDetails._id;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.isFetchingUserDetails = true;
        const { userId } = val.params;
        this.id = userId;
        this.loadData();
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },

  mounted() {
    this.isSuperAdmin =
      localstorageService.getUserData().role.code === "super_admin";
  },
  methods: {
    ...mapActions("etablissements", {
      getEtablissement: "getEtablissement",
      getAgent: "getAgent",
    }),
    ...mapActions("users", {
      action_resetPasswordUser: "resetPasswordUser",
      action_updateUser: "updateUser",
    }),
    // ------------------------------------------------------
    loadData() {
      this.isLoadingTableData = true;
      this.getAgent(this.id)
        .then((res) => {
          const { resource, formsCreate } = res.data || {};
          this.itemDetails = resource;
          if (formsCreate) {
            formsCreate.map((item) => {
              this.forms = this.forms.concat(item.data);
            });
          }
          this.isFetchingUserDetails = false;
          this.isLoadingTableData = false;
        })
        .catch((err) => {
          this.isFetchingUserDetails = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          console.log(err);
        });
    },
    validAccount() {
      this.isLoadingConfirmAccount = true;
      this.action_updateUser({
        id: this.itemDetails._id,
        data: { isConfirmEmail: true },
      })
        .then(async (res) => {
          this.isLoadingConfirmAccount = false;
          this.loadData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Opération réussie",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.isLoadingConfirmAccount = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    sendRecupPasswordMail() {
      this.isLoadingSendMailResetPassword = true;
      this.action_resetPasswordUser({ email: this.itemDetails.email })
        .then(async (res) => {
          this.isLoadingSendMailResetPassword = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Opération réussie",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.isLoadingTableData = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-ctn {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  .img {
    height: 100px;
    width: 100px;
    background: rgba(4, 4, 4, 0.583);
    border-radius: 5px;
  }
}

.res {
  @media (max-width: 768px) {
    margin-top: 15px;
  }
}
</style>
