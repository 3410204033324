<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <!-- Login v1 -->
      <b-card class="">
        <span class="text-center">
          <b-spinner small v-if="isLoading"/> 
          Validation de votre connexion
        </span>
        <!-- form -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.code = this.$route.query.code;
        if(this.code) this.initData();
      },
    },
  },
  data() {
    return {
      isLoading: false,
      code: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    initData(){
      this.isLoading = true
      this.$store.dispatch('utils/getAdminData', this.code).then(response => {
        this.isLoading = false
        console.log('⭕️ utils/getAdminData response === ', response)
        // return
        
      })
      .catch(error => {
        this.isLoading = false
        console.log('error::: ', error);
      })
    }
  } 
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
