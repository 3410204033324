<template>
  <!-- :to="{ name: 'auth-login'}" -->
  <div v-if="!isRegisterPage">
    <b-button
      class="mx-1"
      variant="primary"
      @click="showConfirmeArchiveAgentModal"
    >
      <feather-icon icon="LockIcon" size="16" class="d-inline d-sm-none" />
      <span class="align-middle d-none d-sm-inline">Se connecter</span>
    </b-button>

    <b-modal
      id="modal-1"
      ref="confirmeArchiveAgentModalRef"
      centered
      title="Connexion"
      size="lg"
      hide-footer
    >
      <b-card-text>
        <div class="d-flex justify-content-center gap-3 align-items-center">
          <b-button
            class="mx-1"
            variant="outline-primary"
            href="https://pprodofficial.service-public.bj/official/login?client_id=mtca-siges&redirect_uri=https://siges-tourisme.gouv.bj/api/v1/admins/login-official&response_type=code&scope=openid&authError=true"
          >
            <feather-icon
              icon="LockIcon"
              size="16"
              class="d-inline d-sm-none"
            />
            <span class="align-middle d-none d-sm-inline"
              >Se connecter en tant qu'agent de l'administration</span
            >
          </b-button>
          <b-button class="mx-1" variant="primary" href="/login">
            <feather-icon
              icon="LockIcon"
              size="16"
              class="d-inline d-sm-none"
            />
            <span class="align-middle d-none d-sm-inline"
              >Se connecter en tant qu'opérateur</span
            >
          </b-button>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BDropdown, BDropdownItem, BModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "LoginButton",
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BModal,
  },
  directives: {
    Ripple,
  },

  computed: {
    isRegisterPage() {
      return this.$route.name === "etablissements-create";
    },
  },

  methods: {
    showConfirmeArchiveAgentModal() {
      this.$refs["confirmeArchiveAgentModalRef"].show();
    },
    hideConfirmeArchiveAgentModal() {
      this.$refs["confirmeArchiveAgentModalRef"].hide();
    },
  },
};
</script>
<style lang="scss" scoped></style>
