var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{attrs:{"id":"card-style-variation"}},[_c('div',{staticClass:"mb-2"},[_c('div',[_c('h3',{staticClass:"my-2"},[_vm._v(" Indicateurs sur votre activité (du "+_vm._s(_vm.reverseDate(_vm.filterBy.periode.from, "/"))+" au "+_vm._s(_vm.reverseDate(_vm.filterBy.periode.to, "/"))+") ")])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"col-lg-10 col-md-10"},[_c('b-form-group',{attrs:{"label-cols-xl":"3","label":"Changer la période"}},[_c('b-input-group',{staticClass:"col-md-11 col-lg-11"},[_c('DateRangeWidget',{attrs:{"range":_vm.filterBy.periode,"custom-placeholder":_vm.filterBy.periode,"placeholder":"04-01-2023 au 13-01-2023"},model:{value:(_vm.filterBy.periode),callback:function ($$v) {_vm.$set(_vm.filterBy, "periode", $$v)},expression:"filterBy.periode"}})],1)],1)],1),_c('div',{staticClass:"col-lg-2 col-md-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"w-100",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.loadIndicators()}}},[_vm._v(" Valider ")])],1)])]),_c('hr'),(_vm.loadingIndicators)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"small":""}})],1):_c('div',[(
          _vm.$can('list', 'formulaireFrontiereSortie') ||
          _vm.$can('list', 'formulaireFrontiereEntree') ||
          _vm.can_watch_all_stats
        )?_c('div',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"12","md":"6"}},[_c('ecommerce-statistics',{attrs:{"data":_vm.seriesFrontièreMensuel,"title":_vm.isCommissariatUser
                  ? 'Fiches d\'entrée et de sortie aux frontières terrestres dans votre commissariat'
                  : 'Fiches d\'entrée et de sortie aux frontières terrestres'}})],1)],1)],1):_vm._e(),(
          _vm.$can('create', 'formulaireRestaurantMensuel') || _vm.can_watch_all_stats
        )?_c('div',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"12","md":"6"}},[_c('ecommerce-statistics',{attrs:{"data":_vm.seriesStatsMensuelRestau,"title":"Fiches statistiques mensuelles des établissements de restauration"}})],1)],1)],1):_vm._e(),(
          _vm.$can('create', 'formulaireAgenceVoyageMensuel') ||
          _vm.can_watch_all_stats
        )?_c('div',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"12","md":"6"}},[_c('ecommerce-statistics',{attrs:{"data":_vm.seriesStatsMensuelVoyage,"title":"Fiches statistiques mensuelles des agences de voyages"}})],1)],1)],1):_vm._e(),(
          _vm.$can('create', 'formulaireTransportMensuel') || _vm.can_watch_all_stats
        )?_c('div',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"12","md":"6"}},[_c('ecommerce-statistics',{attrs:{"data":_vm.seriesStatsMensuelTransport,"title":"Fiches statistiques mensuelles des agences de transport de voyageurs"}})],1)],1)],1):_vm._e(),(
          _vm.$can('create', 'formulaireServiceSportifRecreatif') ||
          _vm.can_watch_all_stats
        )?_c('div',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"12","md":"6"}},[_c('ecommerce-statistics',{attrs:{"data":_vm.seriesStatsMensuelSport,"title":"Fiches statistiques mensuelles des agences d'activités sportives et récréatives"}})],1)],1)],1):_vm._e(),(
          _vm.$can('create', 'formulaireServiceCulturel') || _vm.can_watch_all_stats
        )?_c('div',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"12","md":"6"}},[_c('ecommerce-statistics',{attrs:{"data":_vm.seriesStatsMensuelCulture,"title":"Fiches statistiques mensuelles des agences d'activités culturelles"}})],1)],1)],1):_vm._e(),(
          _vm.$can('create', 'formulaireLocationVehiculeSansOpMensuel') ||
          _vm.can_watch_all_stats
        )?_c('div',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"12","md":"6"}},[_c('ecommerce-statistics',{attrs:{"data":_vm.seriesStatsMensuelLocationSansOp,"title":"Fiches statistiques mensuelles des agences de location de véhicules sans opérateurs"}})],1)],1)],1):_vm._e(),(_vm.$can('create', 'formulaireHotelMensuel') || _vm.can_watch_all_stats)?_c('div',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"12","md":"6"}},[_c('ecommerce-statistics',{attrs:{"data":_vm.seriesStatsMensuelHotel,"title":"Fiches statistiques mensuelles des établissements d'hébergement de voyageurs"}})],1)],1)],1):_vm._e(),(_vm.$can('create', 'formulaireHotel') || _vm.can_watch_all_stats)?_c('div',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"12","md":"6"}},[_c('ecommerce-statistics',{attrs:{"data":_vm.seriesClientsMensuelHotel,"title":"Fiches clientes des établissements d'hébergement de voyageurs"}})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"6","md":"6"}},[_c('b-card',[_c('b-card-title',[_c('strong',[_vm._v(" Nombre de clients par genre ")]),_vm._v(" "),_c('br')]),_c('vue-apex-charts',{staticClass:"mt-2 mb-1",attrs:{"type":"pie","height":"325","options":_vm.seriesStatsClientsSexeBis.chartOptions,"series":_vm.seriesStatsClientsSexeBis.series}})],1)],1),_c('b-col',{attrs:{"xl":"6","md":"6"}},[_c('b-card',[_c('b-card-title',[_c('strong',[_vm._v(" Nombre de clients par motif principal de voyage ")])]),_c('vue-apex-charts',{staticClass:"mt-2 mb-1",attrs:{"type":"pie","height":"325","options":_vm.seriesStatsClientsmotifPrincipalVoyage.chartOptions,"series":_vm.seriesStatsClientsmotifPrincipalVoyage.series}})],1)],1)],1)],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }