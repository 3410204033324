// import Contact from '@/views/pages/home/Contacts.vue'
// import RemplirFormulaire from '@/views/apps/formulaires/RemplirFormulaire.vue'
export default [
  // {
  //   path: '/',
  //   name: 'home-public',
  //   component: () => import('@/views/pages/home/Home.vue'),
  //   meta: {
  //     layout: 'default',
  //     resource: 'Public',
  //   },
  // },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackPrefetch: true */ '@/views/pages/home/Contacts.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/formulaires/list/:slug?',
    name: 'formulaires-list',
    component: () => import(/* webpackPrefetch: true */ '@/views/apps/formulaires/ListFormulaire.vue'),
    meta: {
      resource: 'formulaire',
      action: 'list',
      isFormlaireRoute: true,
    },
  },
  {
    path: '/formulaires/create/:slug?',
    name: 'formulaires-create',
    component: () => import(/* webpackPrefetch: true */ '@/views/apps/formulaires/RemplirFormulaire.vue'),
    // component: RemplirFormulaire,
    meta: {
      resource: 'Public',
      action: 'create',
      isFormlaireRoute: true,
      checkFormFillingPeriod: true,
    },
  },
  {
    path: '/formulaires/preview/:slug?',
    name: 'formulaires-preview',
    component: () => import(/* webpackPrefetch: true */ '@/views/apps/formulaires/PreviewFormulaire.vue'),
    meta: {
      resource: 'Public',
      action: 'create',
      isFormlaireRoute: true,
    },
  },
  {
    path: '/formulaires/:model/:id?',
    name: 'formulaires-details',
    component: () => import(/* webpackPrefetch: true */ '@/views/apps/formulaires/DetailsFormulaire.vue'),
    meta: {
      resource: 'Public',
      action: 'read',
      isFormlaireRoute: true,
    },
  },
  {
    path: '/inscription/grand-public',
    name: 'register-default',
    component: () => import('@/views/pages/authentication/registration/RegisterDefault.vue'),
    meta: {
      resource: 'Public',
      layout: 'default',
    },
  },
  // {
  //   path: '/inscription/etablissement-et-agence',
  //   name: 'register-etablissement',
  //   component: () => import(
  //     '@/views/pages/authentication/registration/RegisterEtablissement.vue'
  //   ),
  //   meta: {
  //     resource: 'Public',
  //     // layout: 'full',
  //   },
  // },
  {
    path: '/inscriptions',
    name: 'list-registration-type',
    component: () => import(
      '@/views/pages/authentication/registration/ListRegistrationTypes.vue'
    ),
    meta: {
      resource: 'Public',
      layout: 'full',
    },
  },

  // etablissements
  {
    path: '/etablissements/list',
    name: 'etablissements-list',
    component: () => import('@/views/apps/utilisateurs/etablissements/ListEtablissement.vue'),
    meta: {
      action: 'read',
      resource: 'etablissement',
    },
  },
  {
    path: '/verify-email',
    name: 'etablissements-verify-email',
    component: () => import('@/views/apps/utilisateurs/etablissements/VerifyEmailEtablissement.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/etablissements/create',
    name: 'etablissements-create',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: 'RegisterEtablissement' */ '@/views/apps/utilisateurs/etablissements/CreateEtablissement.vue'),
    meta: {
      resource: 'Public',
    },
  },
  // Add by me
  {
    path: '/etablissements/agents/create-password-agent',
    name: 'etablissements-agents-create-password-agent',
    component: () => import('@/views/apps/utilisateurs/etablissements/agents/components/CreatePasswordAgent.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
      redirectIfLoggedIn: true,
      resource: 'Auth',
    },
  },
  {
    path: '/etablissements/:etablissementId/ajouter-un-agent',
    name: 'etablissements-agents-create',
    component: () => import('@/views/apps/utilisateurs/etablissements/agents/CreateAgent.vue'),
    meta: {
      action: 'create',
      resource: 'agentEtablissement',
    },
  },
  {
    path: '/etablissements/agents/:agentId',
    name: 'etablissements-agents-details',
    component: () => import(/* webpackPrefetch: true */ '@/views/apps/utilisateurs/etablissements/agents/DetailsAgent.vue'),
    meta: {
      action: 'read',
      resource: 'agentEtablissement',
    },
  },
  {
    path: '/etablissements/agents/editer/:agentId',
    name: 'etablissements-agent-edit',
    component: () => import('@/views/apps/utilisateurs/etablissements/agents/EditAgent.vue'),
    meta: {
      action: 'update',
      resource: 'agentEtablissement',
    },
  },
  {
    path: '/etablissements/:etablissementId/liste-des-agents',
    name: 'etablissements-agents-list',
    component: () => import('@/views/apps/utilisateurs/etablissements/agents/ListAgents.vue'),
    meta: {
      action: 'read',
      resource: 'agentEtablissement',
    },
  },
  {
    path: '/etablissements/:etablissementId',
    name: 'etablissements-details',
    component: () => import(/* webpackPrefetch: true */ '@/views/apps/utilisateurs/etablissements/DetailsEtablissement.vue'),
    meta: {
      action: 'read',
      resource: 'etablissementDetails',
    },
  },
  {
    path: '/utilisateurs/parametres',
    name: 'account-settings',
    component: () => import('@/views/apps/utilisateurs/parametres/index.vue'),
    meta: {},
  },

  // Statistics
  {
    path: '/statistiques/etablissements',
    name: 'statistiques-etablissements',
    component: () => import('@/views/statistics/etablissements/Index.vue'),
    meta: {
      resource: 'stats',
    },
  },
  {
    path: '/statistiques/agences-transport',
    name: 'stats-agences-transport',
    component: () => import('@/views/statistics/agences-transport/Index.vue'),
    meta: {
      resource: 'stats',
    },
  },
  {
    path: '/statistiques/agences-location-sans-operateur',
    name: 'stats-agences-location-sans-operateur',
    component: () => import('@/views/statistics/agences-location-sans-operateur/Index.vue'),
    meta: {
      resource: 'stats',
    },
  },
  {
    path: '/statistiques/etablissement-de-restauration',
    name: 'stats-etablissements-restauration',
    component: () => import('@/views/statistics/agences-restauration/Index.vue'),
    meta: {
      resource: 'stats',
    },
  },
  {
    path: '/statistiques-agence-voyage',
    name: 'stats-agences-voyage',
    component: () => import('@/views/statistics/agences-voyage/Index.vue'),
    meta: {
      resource: 'stats',
    },
  },
  {
    path: '/stats-industries-touristiques',
    name: 'stats-industries-touristiques',
    component: () => import('@/views/statistics/industries-touristiques/Index.vue'),
    meta: {
      resource: 'stats',
    },
  },
  {
    path: '/statistiques-etablissement-hebergement',
    name: 'stats-etablissement-hebergement',
    component: () => import('@/views/statistics/etablissements-hebergement/Index.vue'),
    meta: {
      resource: 'stats',
    },
  },
  {
    path: '/statistiques/frontieres-terrestres',
    name: 'stats-frontieres-terrestres',
    component: () => import('@/views/statistics/frontieres-terrestres/Index.vue'),
    meta: {
      resource: 'stats',
    },
  },
  // {
  //   path: '/statistiques/aeroport',
  //   name: 'statistiques-aeroport',
  //   component: () => import('@/views/statistics/aeroports/Index.vue'),
  //   meta: {
  //     resource: 'Public',
  //   },
  // },

  // Agents MTCA
  {
    path: '/agents/create',
    name: 'agents-create',
    component: () => import('@/views/apps/utilisateurs/agentMtca/CreateAgent.vue'),
    meta: {
      action: 'create',
      resource: 'agentMtca',
    },
  },
  {
    path: '/agents/listes-des-agents',
    name: 'agents-listes-des-agents',
    component: () => import('@/views/apps/utilisateurs/agentMtca/ListAgents.vue'),
    meta: {
      action: 'read',
      resource: 'agentMtca',
    },
  },
  {
    path: '/agents/:agentId',
    name: 'agents-details',
    component: () => import(/* webpackPrefetch: true */ '@/views/apps/utilisateurs/agentMtca/DetailsAgent.vue'),
    meta: {
      action: 'read',
      resource: 'agentMtca',
    },
  },

  // Commissaires
  {
    path: '/commissariats/create',
    name: 'commissariats-create',
    component: () => import('@/views/apps/utilisateurs/commissariat/CreateCommissariat.vue'),
    meta: {
      action: 'create',
      resource: 'commissaire',
    },
  },
  {
    path: '/commissariats/listes-des-agents',
    name: 'commissariats-list',
    component: () => import('@/views/apps/utilisateurs/commissariat/ListCommissariat.vue'),
    meta: {
      action: 'read',
      resource: 'commissaire',
    },
  },
  {
    path: '/commissariats/:agentId',
    name: 'commissariats-details',
    component: () => import('@/views/apps/utilisateurs/commissariat/DetailsCommissariat.vue'),
    meta: {
      action: 'read',
      resource: 'commissaire',
    },
  },

]
